import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../Auth';
import { useNotification } from '../components/NotificationContext';
import { PendingActionContext } from '../App';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth } from '../firebase';

const Login = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLinkSent, setIsLinkSent] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const { showNotification } = useNotification();
  const { pendingAction, setPendingAction } = useContext(PendingActionContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const actionCodeSettings = {
        url: window.location.origin,
        handleCodeInApp: true,
      };

      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      showNotification('Check your email to complete sign-in.', 'success');
      setIsLinkSent(true);
    } catch (error) {
      showNotification(error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePostLoginNavigation = (user) => {
    if (pendingAction && pendingAction.type === 'RSVP') {
      navigate(`/event/${pendingAction.eventUrl}`);
      setPendingAction(null);
    } else {
      navigate('/my-events');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col py-6 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-2xl">
        <h2 className="text-center text-2xl font-extrabold text-gray-900">
          Welcome to Gatherly
        </h2>
        <div className="mt-4 relative">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center">
            <span className="px-3 bg-gray-100 text-sm text-gray-500">
              The simple way to organize gatherings
            </span>
          </div>
        </div>
      </div>

      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-2xl">
        <div className="bg-white shadow-lg overflow-hidden">
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 p-6 bg-gradient-to-br from-blue-50 to-blue-100">
              <h3 className="text-lg font-bold text-blue-800 mb-2">No sign-in required to</h3>
              <ul className="mb-6 space-y-2">
                <li className="flex items-center text-sm text-gray-600">
                  <i className="fas fa-eye h-5 w-5 mr-2 text-blue-500"></i>
                  View gatherings
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <i className="fas fa-check-circle h-5 w-5 mr-2 text-blue-500"></i>
                  RSVP to gatherings
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <i className="fas fa-calendar-alt h-5 w-5 mr-2 text-blue-500"></i>
                  Add events to your calendar
                </li>
              </ul>
              <Link
                to="/"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                View Events
              </Link>
            </div>

            <div className="flex-1 p-6 bg-gradient-to-br from-blue-500 to-blue-600 text-white">
              <h3 className="text-lg font-bold mb-2">Sign up or Sign in to</h3>
              <ul className="mb-6 space-y-2">
                <li className="flex items-center text-sm">
                  <i className="fas fa-plus h-5 w-5 mr-2 text-blue-200"></i>
                  Create your own gatherings
                </li>
                <li className="flex items-center text-sm">
                  <i className="fas fa-clipboard-list h-5 w-5 mr-2 text-blue-200"></i>
                  Manage your RSVPs
                </li>
                <li className="flex items-center text-sm">
                  <i className="fas fa-history h-5 w-5 mr-2 text-blue-200"></i>
                  Access past gatherings
                </li>
              </ul>
              <form className="space-y-4" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-blue-200">
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email address"
                      className="appearance-none block w-full px-3 py-2 border border-blue-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-gray-900"
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-blue-600 bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                  >
                    {isLoading ? 'Creating magic link...' : isLinkSent ? 'Check email' : 'Sign up or Sign in'}
                  </button>
                </div>
              </form>
              <p className="mt-2 text-center text-xs text-blue-200">
                You'll receive a magic link via email to sign in. No password required!
              </p>
            </div>
          </div>
        </div>

        {process.env.NODE_ENV === 'development' && (
          <div className="mt-6">
            <Link
              to="/admin-login"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-150 ease-in-out"
            >
              Admin Login
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
