import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';
import { useNotification } from './NotificationContext';

const LogoutButton = ({ className }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  const handleLogout = async () => {
    try {
      await logout();
      showNotification('Signed out successfully', 'success');
      navigate('/');
      // Force a page reload to ensure all components update
      window.location.reload();
    } catch (error) {
      console.error('Error during sign out:', error);
      showNotification('Error during sign out: ' + error.message, 'error');
    }
  };

  return (
    <button onClick={handleLogout} className={className}>
      Sign out
    </button>
  );
};

export default LogoutButton;