import React, { useEffect } from 'react';
import { useNotification } from './NotificationContext';

const Notification = () => {
  const { notification, hideNotification } = useNotification();

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        hideNotification();
      }, 5000); // Notification will disappear after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [notification, hideNotification]);

  if (!notification) return null;

  const { message, type } = notification;

  const getIcon = () => {
    switch (type) {
      case 'success':
        return <i className="fas fa-check-circle text-green-500"></i>;
      case 'error':
        return <i className="fas fa-exclamation-triangle text-red-500"></i>;
      case 'info':
      default:
        return <i className="fas fa-info-circle text-blue-500"></i>;
    }
  };

  const getBgColor = () => {
    switch (type) {
      case 'success':
        return 'bg-green-100 border-green-500';
      case 'error':
        return 'bg-red-100 border-red-500';
      case 'info':
      default:
        return 'bg-blue-100 border-blue-500';
    }
  };

  return (
    <div
      className={`fixed top-4 right-4 max-w-sm w-full ${getBgColor()} border-l-4 p-4 rounded-md shadow-lg flex items-center justify-between animate-slide-in`}
      style={{ zIndex: 'var(--z-notification)' }}
    >
      <div className="flex items-center">
        <div className="flex-shrink-0 mr-3">
          {getIcon()}
        </div>
        <div className="text-sm font-medium text-gray-900">
          {message}
        </div>
      </div>
      <button
        onClick={hideNotification}
        className="text-gray-400 hover:text-gray-500 focus:outline-none"
      >
        <i className="fas fa-times"></i>
      </button>
    </div>
  );
};

export default Notification;
