import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../Auth';
import { getGroupByUrl, joinGroup, checkGroupMembership } from './groupServices';
import { useRequireAuth } from '../hooks/useRequireAuth';
import GroupManager from './GroupManager';
import { useNotification } from '../components/NotificationContext';

const GroupPage = () => {
  const { groupUrl } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const fetchGroup = async () => {
      setLoading(true);
      try {
        const fetchedGroup = await getGroupByUrl(groupUrl);
        if (fetchedGroup) {
          setGroup(fetchedGroup);
          if (user) {
            const isMember = await checkGroupMembership(user.uid, groupUrl);
            setHasAccess(isMember);
          }
        } else {
          setError('Group not found');
          navigate('/whoops');
        }
      } catch (err) {
        setError('Error fetching group');
        navigate('/whoops');
      } finally {
        setLoading(false);
      }
    };

    fetchGroup();
  }, [groupUrl, user, navigate, location.key]);

  const handleGroupUpdated = async (updatedGroup) => {
    if (updatedGroup === null) {
      navigate('/my-groups');
    } else {
      setGroup(updatedGroup);
    }
  };

  const handleJoinGroup = async (e) => {
    e.preventDefault();
    try {
      if (user) {
        await joinGroup(groupUrl, user.email, groupCode, user);
        setHasAccess(true);
        setGroup(prevGroup => ({
          ...prevGroup,
          members: [...prevGroup.members, {
            uid: user.uid,
            email: user.email,
            name: user.displayName || user.email,
            photoURL: user.photoURL
          }]
        }));
        showNotification('Successfully joined the group', 'success');
      } else {
        const isMember = await checkGroupMembership(email, groupUrl);
        if (isMember) {
          const success = await joinGroup(groupUrl, email, groupCode);
          if (success) {
            setHasAccess(true);
            setGroup(prevGroup => ({
              ...prevGroup,
              members: [...prevGroup.members, { email }]
            }));
            showNotification('Successfully accessed the group', 'success');
          } else {
            showNotification('Invalid group code', 'error');
          }
        } else {
          showNotification('Email not found in group members', 'error');
        }
      }
    } catch (err) {
      showNotification('Failed to join group. Please check your email and group code.', 'error');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!hasAccess) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white p-8 rounded-lg shadow-xl max-w-md mx-auto">
          <h2 className="text-2xl font-bold mb-4">Join Group: {group?.name}</h2>
          <form onSubmit={handleJoinGroup} className="space-y-4">
            {!user && (
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
            )}
            <div>
              <label htmlFor="groupCode" className="block text-sm font-medium text-gray-700">Group Code</label>
              <input
                type="text"
                id="groupCode"
                value={groupCode}
                onChange={(e) => setGroupCode(e.target.value)}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                {user ? 'Join Group' : 'Access Group'}
              </button>
              <button
                type="button"
                onClick={() => navigate('/events')}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
              >
                View Public Events
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto">
      <GroupManager
        key={groupUrl}
        group={group}
        onGroupUpdated={handleGroupUpdated}
      />
    </div>
  );
};

export default GroupPage;
