import { auth, db } from '../firebase';
import { doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { getUserProfileByEmail } from './userManagement'; // Add this import

const SESSION_KEY = 'userSession';
const SESSION_DURATION = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

// Create a session and store it in localStorage
export const createSession = (email, isGuest = false) => {
  const session = {
    email,
    isGuest,
    expiresAt: Date.now() + SESSION_DURATION,
  };
  localStorage.setItem(SESSION_KEY, JSON.stringify(session));
};

// Retrieve the session from localStorage
export const getSession = () => {
  const sessionStr = localStorage.getItem(SESSION_KEY);
  if (!sessionStr) return null;

  const session = JSON.parse(sessionStr);
  if (Date.now() > session.expiresAt) {
    clearSession();
    return null;
  }
  return session;
};

// Update the session in localStorage with additional data
export const updateSession = (updates) => {
  const session = getSession();
  if (session) {
    const updatedSession = { ...session, ...updates };
    localStorage.setItem(SESSION_KEY, JSON.stringify(updatedSession));
  }
};

// Clear the session from localStorage
export const clearSession = () => {
  localStorage.removeItem(SESSION_KEY);
};

// Refresh the session expiration time
export const refreshSession = () => {
  const session = getSession();
  if (session) {
    session.expiresAt = Date.now() + SESSION_DURATION;
    localStorage.setItem(SESSION_KEY, JSON.stringify(session));
  }
};

// Get the user data from the session
export const getSessionUser = async () => {
  const session = getSession();
  if (!session) return null;

  let userDoc = await getUserProfileByEmail(session.email);

  if (userDoc) {
    return { ...userDoc, isGuest: session.isGuest };
  }

  return null;
};

// Upgrade a guest session to a full user session
export const upgradeSessionToFullUser = () => {
  const session = getSession();
  if (session && session.isGuest) {
    updateSession({ isGuest: false });
  }
};
