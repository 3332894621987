import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';
import { checkGroupMembership } from '../groups/groupServices';

export function useRequireAuth(options = {}) {
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { 
    redirectUrl = '/whoops', 
    allowGuest = true,
    requireGroup = false,
    groupUrl = null
  } = options;

  const [isLoading, setIsLoading] = useState(true);
  const [hasGroupAccess, setHasGroupAccess] = useState(false);

  useEffect(() => {
    async function checkAuth() {
      if (!isAuthenticated) {
        navigate(redirectUrl);
        setIsLoading(false);
        return;
      }

      if (!allowGuest && user?.isGuest) {
        navigate('/profile');
        setIsLoading(false);
        return;
      }

      if (requireGroup && groupUrl) {
        try {
          const isMember = await checkGroupMembership(user.uid, groupUrl);
          setHasGroupAccess(isMember);
          // Remove the automatic redirection
          // if (!isMember) {
          //   navigate('/whoops');
          // }
        } catch (error) {
          console.error('Error checking group membership:', error);
          // Remove the automatic redirection
          // navigate('/whoops');
        }
      } else {
        setHasGroupAccess(true);
      }

      setIsLoading(false);
    }

    checkAuth();
  }, [isAuthenticated, user, navigate, redirectUrl, allowGuest, requireGroup, groupUrl]);

  return { user, isLoading, hasGroupAccess };
}
