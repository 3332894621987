import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { formatDateTime } from '../utils/dateUtils'; // Assuming you have a dateUtils file for formatting
import { generateICSLink } from '../events/eventActions'; // Import the existing ICS generation function

const EMAIL_COLLECTION = 'EMAIL_COLLECTION';

const EMAIL_TEMPLATES = {
  WELCOME: 'jy7zpl90yy3l5vx6',
  EVENT_DETAIL: '3z0vklormq1l7qrx'
};

export async function sendWelcomeEmail(recipientEmail, recipientName) {
  console.log('sendWelcomeEmail called for:', recipientEmail);
  
  const emailData = {
    to: [
      {
        email: recipientEmail,
        name: recipientName || 'Friend'  // Provide a default name if not provided
      }
    ],
    from: {
      email: 'no-reply@gatherly.org',
      name: 'Gatherly'
    },
    subject: 'Welcome to Gatherly!',
    template_id: EMAIL_TEMPLATES.WELCOME,
    variables: [
      {
        email: recipientEmail,
        substitutions: [
          {
            var: 'name',
            value: recipientName || 'Friend'  // Provide a default name if not provided
          },
          {
            var: 'company',
            value: 'Gatherly'
          }
        ]
      }
    ]
  };

  console.log('Welcome email data being sent:', JSON.stringify(emailData, null, 2));

  return sendEmail(emailData);
}

export async function sendEventDetailEmail(recipientEmail, recipientName, event, isRSVP = false, isUpdate = false) {
  console.log('Sending event detail email:', { recipientEmail, recipientName, event, isRSVP, isUpdate });
  
  const formattedDate = formatDateTime(event.startDate, event.startTime, event.endDate, event.endTime, event.timezone);
  const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.location)}`;

  // Generate the ICS file link
  const icsFileLink = `${window.location.origin}/event/${event.url}/ics`;

  let subject;
  if (isUpdate) {
    subject = `Head's up: Some changes were made to ${event.name}`;
  } else if (isRSVP) {
    subject = `RSVP'd to ${event.name}`;
  } else {
    subject = `Check out this gathering - ${event.name}`;
  }

  const emailData = {
    to: [
      {
        email: recipientEmail,
        name: recipientName || 'Gatherly User'  // Provide a default name if not provided
      }
    ],
    from: {
      email: 'no-reply@gatherly.org',
      name: 'Gatherly'
    },
    subject: subject,
    template_id: EMAIL_TEMPLATES.EVENT_DETAIL,
    variables: [
      {
        email: recipientEmail,
        substitutions: [
          { var: 'recipient_name', value: recipientName || 'Gatherly User' },
          { var: 'event_name', value: event.name },
          { var: 'event_url', value: `${window.location.origin}/event/${event.url}` },
          { var: 'event_date', value: formattedDate },
          { var: 'event_location', value: event.location },
          { var: 'event_location_link', value: googleMapsLink },
          { var: 'event_description', value: event.description },
          { var: 'host_name', value: event.hostName || 'The Gatherly Team' },
          { var: 'host_email', value: event.hostEmail || 'support@gatherly.org' },
          { var: 'ics_file_link', value: icsFileLink }
        ]
      }
    ]
  };

  console.log('Event detail email data being sent:', JSON.stringify(emailData, null, 2));

  return sendEmail(emailData);
}

export async function sendEmail(emailData) {
  console.log('Attempting to send email:', emailData);
  try {
    const docRef = await addDoc(collection(db, EMAIL_COLLECTION), emailData);
    console.log('Email document written with ID: ', docRef.id);
    return { success: true, id: docRef.id };
  } catch (error) {
    console.error('Error sending email: ', error);
    console.log('Error details:', error.code, error.message);
    if (error.message.includes('must be one of the verified domains')) {
      console.log('Development mode: Simulating email send');
      return { 
        success: true, 
        id: 'dev-mode-' + Date.now(),
        message: 'Email simulated in development mode'
      };
    }
    return { success: false, error: error.message };
  }
}

// Keep the sendTestEmail function for testing purposes
export async function sendTestEmail() {
  return sendWelcomeEmail('joshjmanning@gmail.com', 'Josh Manning');
}
