import React, { useState, createContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './Auth';
import Header from './components/Header';
import EventList from './events/EventList';
import EventFormManager from './events/EventFormManager';
import EventDetailMain from './events/EventDetailMain';
import Login from './users/Login';
import AdminLogin from './users/AdminLogin';
import Profile from './users/Profile';
import MyEvents from './events/MyEvents';
import Notification from './components/Notification';
import CreateTestUser from './users/CreateTestUser';
import Footer from './components/Footer';
import { NotificationProvider } from './components/NotificationContext';
import Whoops from './components/Whoops';
import { useRequireAuth } from './hooks/useRequireAuth';
import ICSFileDownload from './components/ICSFileDownload';
import MyGroups from './groups/MyGroups';
import GroupPage from './groups/GroupPage';

export const PendingActionContext = createContext(null);

// Update ProtectedRoute to allow all authenticated users
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  
  if (!isAuthenticated) {
    return <Navigate to="/whoops" />;
  }

  return children;
};

function App() {
  const [pendingAction, setPendingAction] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    timeFrame: 'all',
    myEvents: false,
    rsvpd: false,
  });
  const [sortOrder, setSortOrder] = useState('desc');

  const FilterSection = () => (
    <EventList.FilterSection
      activeFilters={activeFilters}
      setActiveFilters={setActiveFilters}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
    />
  );

  return (
    <NotificationProvider>
      <Router>
        <AuthProvider>
          <PendingActionContext.Provider value={{ pendingAction, setPendingAction }}>
            <div className="flex flex-col min-h-screen bg-gray-100 font-sans">
              <Header 
                searchTerm={searchTerm} 
                setSearchTerm={setSearchTerm} 
                isFilterExpanded={isFilterExpanded} 
                setIsFilterExpanded={setIsFilterExpanded}
                FilterSection={FilterSection}
              />
              <Notification />
              <main className="flex-grow mt-16">
                <Routes>
                  <Route path="/" element={
                    <EventList 
                      searchTerm={searchTerm} 
                      setSearchTerm={setSearchTerm} 
                      isFilterExpanded={isFilterExpanded} 
                      setIsFilterExpanded={setIsFilterExpanded}
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      sortOrder={sortOrder}
                      setSortOrder={setSortOrder}
                    />
                  } />
                  <Route path="/login" element={<Login />} />
                  <Route path="/admin-login" element={<AdminLogin />} />
                  <Route 
                    path="/profile" 
                    element={
                      <ProtectedRoute>
                        <Profile />
                      </ProtectedRoute>
                    } 
                  />
                  <Route 
                    path="/my-events" 
                    element={
                      <ProtectedRoute>
                        <MyEvents />
                      </ProtectedRoute>
                    } 
                  />
                  <Route 
                    path="/create-event" 
                    element={
                      <ProtectedRoute>
                        <EventFormManager />
                      </ProtectedRoute>
                    } 
                  />
                  <Route 
                    path="/manage-event" 
                    element={
                      <ProtectedRoute>
                        <EventFormManager />
                      </ProtectedRoute>
                    } 
                  />
                  <Route 
                    path="/manage-event/:eventUrl" 
                    element={
                      <ProtectedRoute>
                        <EventFormManager />
                      </ProtectedRoute>
                    } 
                  />
                  <Route path="/event/:eventUrl" element={<EventDetailMain />} />
                  <Route path="/event/:eventUrl/ics" element={<ICSFileDownload />} />
                  <Route path="/whoops" element={<Whoops />} />
                  <Route path="/my-groups" element={<MyGroups />} />
                  <Route path="/group/:groupUrl" element={<GroupPage />} />
                </Routes>
              </main>
              <Footer />
            </div>
          </PendingActionContext.Provider>
        </AuthProvider>
      </Router>
    </NotificationProvider>
  );
}

export default App;
