import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc, updateDoc, addDoc, arrayUnion, arrayRemove, collection, query, where, getDocs } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useAuth } from '../Auth';
import { useNotification } from '../components/NotificationContext';
import { uploadImage } from '../utils/imageUtils';
import { toggleRequiresRSVP } from '../rsvps/rsvpService';
import { useRequireAuth } from '../hooks/useRequireAuth';
import CreateGroupModal from '../groups/CreateGroupModal';
import { updateGroupEventRelationship } from '../utils/groupEventRelationship';
import { sendEventDetailEmail } from '../emails/emailService';
import { getRSVPs } from '../rsvps/rsvpService';
import ConfirmationModal from '../components/ConfirmationModal';

const EventFormManager = ({ isModal = false, onClose, initialEvent = null, onEventSaved }) => {
  useRequireAuth({ allowGuest: true });

  const { eventUrl } = useParams();
  const isEditMode = !!initialEvent || !!eventUrl;
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isAuthenticated } = useAuth();
  const { showNotification } = useNotification();

  const [event, setEvent] = useState(initialEvent || {
    name: '',
    startDate: new Date().toISOString().split('T')[0],
    startTime: new Date().toTimeString().split(' ')[0].slice(0, 5),
    endDate: new Date().toISOString().split('T')[0],
    endTime: new Date().toTimeString().split(' ')[0].slice(0, 5),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    locationName: '',
    location: '',
    description: '',
    url: '',
    imageUrl: '',
    requiresRSVP: true,
    isPrivate: false,
  });

  const [loading, setLoading] = useState(isEditMode && !initialEvent);
  const [image, setImage] = useState(null);
  const [removeImage, setRemoveImage] = useState(false);
  const [smartUrl, setSmartUrl] = useState('');
  const [customUrl, setCustomUrl] = useState('');
  const [urlError, setUrlError] = useState('');
  const [originalEvent, setOriginalEvent] = useState(null);
  const [showRSVPWarning, setShowRSVPWarning] = useState(false);
  const [returnPath, setReturnPath] = useState('/my-events');
  const [dateTimeError, setDateTimeError] = useState('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  const commonTimezones = [
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'America/Anchorage',
    'Pacific/Honolulu',
    'Europe/London',
    'Europe/Paris',
    'Asia/Tokyo',
    'Australia/Sydney',
    'Pacific/Auckland',
  ];

  const timezoneOptions = [
    { value: '', label: 'Select a timezone' },
    ...commonTimezones.map(tz => ({ value: tz, label: tz.replace('_', ' ') })),
    { value: 'other', label: 'Other' },
  ];

  const [showCustomTimezone, setShowCustomTimezone] = useState(false);

  const [userGroups, setUserGroups] = useState([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState(initialEvent?.selectedGroupIds || []);
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const groupsPerPage = 5;

  useEffect(() => {
    if (isEditMode && !initialEvent) {
      fetchEvent();
    } else {
      setLoading(false);
    }
  }, [isEditMode, eventUrl, initialEvent]);

  useEffect(() => {
    if (isEditMode) {
      setCustomUrl(event.url);
    } else {
      setSmartUrl(generateSmartUrl(event.name));
    }
  }, [isEditMode, event.name, event.url]);

  useEffect(() => {
    if (user) {
      fetchUserGroups();
    }
  }, [user]);

  useEffect(() => {
    if (initialEvent) {
      setEvent(initialEvent);
      setOriginalEvent(JSON.parse(JSON.stringify(initialEvent)));
      setSelectedGroupIds(initialEvent.groupIds || []);
      setCustomUrl(initialEvent.url || '');
    }
  }, [initialEvent]);

  const fetchUserGroups = async () => {
    try {
      const groupsQuery = query(collection(db, 'groups'), where('createdBy', '==', user.uid));
      const groupsSnapshot = await getDocs(groupsQuery);
      const groups = groupsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUserGroups(groups);
    } catch (error) {
      console.error('Error fetching user groups:', error);
      showNotification('Error fetching groups', 'error');
    }
  };

  const fetchEvent = async () => {
    try {
      const eventId = location.state?.eventId;
      if (!eventId) {
        // If eventId is not in state, fetch it using the URL
        const eventsRef = collection(db, 'events');
        const q = query(eventsRef, where('url', '==', eventUrl));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const eventDoc = querySnapshot.docs[0];
          const eventData = { id: eventDoc.id, ...eventDoc.data() };
          setEvent(eventData);
          setOriginalEvent(JSON.parse(JSON.stringify(eventData)));
          setCustomUrl(eventData.url);
          setSelectedGroupIds(eventData.groupIds || []);
        } else {
          throw new Error('Event not found');
        }
      } else {
        // Fetch event using the eventId from state
        const eventDoc = await getDoc(doc(db, 'events', eventId));
        if (eventDoc.exists()) {
          const eventData = { id: eventDoc.id, ...eventDoc.data() };
          setEvent(eventData);
          setOriginalEvent(JSON.parse(JSON.stringify(eventData)));
          setCustomUrl(eventData.url);
          setSelectedGroupIds(eventData.groupIds || []);
        } else {
          throw new Error('Event not found');
        }
      }
    } catch (error) {
      console.error('Error fetching event:', error);
      showNotification('Error fetching event: ' + error.message, 'error');
      if (onClose) onClose();
      else navigate('/my-events');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEvent(prevEvent => ({
      ...prevEvent,
      [name]: type === 'checkbox' ? checked : value
    }));
    setHasUnsavedChanges(true);

    if (name === 'requiresRSVP' && isEditMode) {
      if (!checked) {
        setShowRSVPWarning(true);
      } else {
        setEvent(prevEvent => ({ ...prevEvent, [name]: checked }));
        setHasUnsavedChanges(true);
      }
    } else {
      setEvent(prevEvent => ({
        ...prevEvent,
        [name]: type === 'checkbox' ? checked : value
      }));
      setHasUnsavedChanges(true);
    }

    if (name === 'name' && !isEditMode) {
      setSmartUrl(generateSmartUrl(value));
    }

    // Validate end date and time
    if (name === 'endDate' || name === 'endTime') {
      validateDateTime({
        ...event,
        [name]: value
      });
    }
  };

  const generateSmartUrl = (name) => {
    let url = name.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
    url = url.split('-').slice(0, 5).join('-');
    return url;
  };

  const handleCustomUrlChange = (e) => {
    const value = e.target.value.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
    setCustomUrl(value);
    validateUrl(value);
  };

  const validateUrl = (url) => {
    if (url.length > 0 && url.length < 3) {
      setUrlError('URL must be at least 3 characters long');
    } else if (url.length > 50) {
      setUrlError('URL must be no more than 50 characters long');
    } else {
      setUrlError('');
    }
  };

  const checkUrlAvailability = async (url) => {
    if (isEditMode && url === originalEvent.url) return true;
    const eventsRef = collection(db, 'events');
    const q = query(eventsRef, where('url', '==', url.toLowerCase()));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty;
  };

  const handleDescriptionChange = (event, editor) => {
    const data = editor.getData();
    setEvent(prevEvent => ({
      ...prevEvent,
      description: data
    }));
    setHasUnsavedChanges(true);  // Add this line
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setRemoveImage(false);
      setHasUnsavedChanges(true);
    }
  };

  const handleRSVPWarningConfirm = async () => {
    try {
      const result = await toggleRequiresRSVP(event.id);
      if (result.success) {
        setEvent(prevEvent => ({ ...prevEvent, requiresRSVP: false, rsvpCount: 0 }));
        setShowRSVPWarning(false);
        showNotification(result.message, 'success');
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error('Error updating RSVP requirement:', error);
      showNotification(`Error updating RSVP requirement: ${error.message}`, 'error');
    }
  };

  const validateDateTime = (eventData) => {
    const startDateTime = new Date(`${eventData.startDate}T${eventData.startTime}`);
    const endDateTime = new Date(`${eventData.endDate}T${eventData.endTime}`);

    if (endDateTime < startDateTime) {
      setDateTimeError('End date and time cannot be earlier than start date and time.');
    } else {
      setDateTimeError('');
    }
  };

  const handleSubmit = async (e, shouldNotify = true) => {
    e.preventDefault();
    console.log('handleSubmit called with shouldNotify:', shouldNotify);
    if (!isAuthenticated) {
      showNotification('You must be logged in to create or edit events', 'error');
      return;
    }

    // Validate date and time before submitting
    validateDateTime(event);
    if (dateTimeError) {
      showNotification(dateTimeError, 'error');
      return;
    }

    try {
      let imageUrl = event.imageUrl;
      if (image) {
        const path = `images/${Date.now()}_${image.name}`;
        imageUrl = await uploadImage(image, path);
      } else if (removeImage) {
        imageUrl = '';
      }

      let savedEvent;
      if (isEditMode) {
        const eventRef = doc(db, 'events', event.id);
        await updateDoc(eventRef, {
          ...event,
          imageUrl,
          groupIds: selectedGroupIds,  // Ensure this is updated
          updatedAt: new Date(),
        });
        savedEvent = { ...event, id: event.id, groupIds: selectedGroupIds, imageUrl };
        console.log('Event updated successfully:', savedEvent);
        showNotification('Event updated successfully', 'success');
        
        if (shouldNotify) {
          console.log('Notifying attendees');
          await notifyAttendees(savedEvent);
        }

        const oldGroupIds = event.groupIds || [];
        const newGroupIds = selectedGroupIds;

        // Groups to add
        const groupsToAdd = newGroupIds.filter(id => !oldGroupIds.includes(id));
        // Groups to remove
        const groupsToRemove = oldGroupIds.filter(id => !newGroupIds.includes(id));

        // Update relationships
        for (let groupId of groupsToAdd) {
          await updateGroupEventRelationship(event.id, groupId, true);
        }
        for (let groupId of groupsToRemove) {
          await updateGroupEventRelationship(event.id, groupId, false);
        }

        // Fetch updated group information
        const updatedGroups = await Promise.all(
          selectedGroupIds.map(async (groupId) => {
            const groupDoc = await getDoc(doc(db, 'groups', groupId));
            return groupDoc.data();
          })
        );

        // Update local state with new group information
        setUserGroups(prevGroups => {
          const updatedGroupMap = new Map(updatedGroups.map(g => [g.id, g]));
          return prevGroups.map(g => updatedGroupMap.get(g.id) || g);
        });
      } else {
        const urlToUse = customUrl || smartUrl;
        const isUrlAvailable = await checkUrlAvailability(urlToUse);
        if (!isUrlAvailable) {
          showNotification('This URL is already taken. Please choose a different one.', 'error');
          return;
        }

        const eventData = {
          ...event,
          imageUrl,
          url: urlToUse.toLowerCase(),
          createdBy: user.uid,
          hostName: user.displayName || user.name || user.email,
          hostEmail: user.email,
          groupIds: selectedGroupIds,
        };

        const eventRef = await addDoc(collection(db, 'events'), eventData);
        savedEvent = { ...eventData, id: eventRef.id };
        showNotification('Event created successfully!', 'success');
        
        // Redirect to the new event's detail page
        navigate(`/event/${savedEvent.url}`);
      }

      if (onEventSaved) {
        console.log('Calling onEventSaved');
        onEventSaved(savedEvent, !isEditMode || !shouldNotify);
      }

      if (isModal) {
        onClose();
      } else {
        navigate(returnPath);
      }
    } catch (error) {
      console.error('Error saving event:', error);
      showNotification('Error saving event: ' + error.message, 'error');
    }
  };

  const notifyAttendees = async (event) => {
    try {
      const rsvps = await getRSVPs(event.id);
      console.log('RSVPs fetched:', rsvps);
      const attendeeEmails = rsvps.map(rsvp => rsvp.email);
      console.log('Attendee emails:', attendeeEmails);
      
      for (const email of attendeeEmails) {
        console.log('Sending email to:', email);
        const result = await sendEventDetailEmail(email, 'Attendee', event, false, true);
        console.log('Email send result:', result);
      }
      showNotification('Attendees have been notified', 'success');
    } catch (error) {
      console.error('Error notifying attendees:', error);
      showNotification('Error notifying attendees: ' + error.message, 'error');
    }
  };

  const handleCancel = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedChangesModal(true);
    } else {
      closeForm();
    }
  };

  const closeForm = () => {
    if (isModal && onClose) {
      onClose();
    } else {
      navigate(returnPath);
    }
  };

  const handleConfirmClose = () => {
    setShowUnsavedChangesModal(false);
    closeForm();
  };

  const hasChanges = () => {
    return JSON.stringify(event) !== JSON.stringify(originalEvent);
  };

  const renderRequiredAsterisk = () => <span className="text-red-500">*</span>;

  const handleTimezoneChange = (e) => {
    const value = e.target.value;
    if (value === 'other') {
      setShowCustomTimezone(true);
    } else {
      setShowCustomTimezone(false);
      setEvent(prevEvent => ({ ...prevEvent, timezone: value }));
    }
  };

  const handleGroupCreated = (newGroup) => {
    if (newGroup && newGroup.id) {
      setUserGroups(prevGroups => [...prevGroups, newGroup]);
      setSelectedGroupIds(prevIds => [...prevIds, newGroup.id]);
      setIsCreateGroupModalOpen(false);
    } else {
      console.error('New group is missing an id:', newGroup);
      showNotification('Error adding new group. Please try again.', 'error');
    }
  };

  const filteredGroups = userGroups.filter(group =>
    group.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(filteredGroups.length / groupsPerPage);
  const indexOfLastGroup = currentPage * groupsPerPage;
  const indexOfFirstGroup = indexOfLastGroup - groupsPerPage;
  const currentGroups = filteredGroups.slice(indexOfFirstGroup, indexOfLastGroup);

  const toggleGroupSelection = (groupId) => {
    setSelectedGroupIds(prevIds =>
      prevIds.includes(groupId)
        ? prevIds.filter(id => id !== groupId)
        : [...prevIds, groupId]
    );
    setHasUnsavedChanges(true);  // Mark that changes have been made
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  if (loading) {
    return <div className="text-center py-4">Loading...</div>;
  }

  return (
    <div className={isModal ? "p-4 text-gray-800" : "max-w-4xl mx-auto px-4 py-8"}>
      <h1 className="text-3xl font-bold mb-8">{isEditMode ? 'Edit Event' : 'New Event'}</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
            Event Name {renderRequiredAsterisk()}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={event.name}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        {isEditMode ? (
          <div>
            <label htmlFor="customUrl" className="block text-sm font-medium text-gray-700 mb-1">
              Event URL <span className="text-sm text-gray-500">(Cannot be changed)</span>
            </label>
            <input
              type="text"
              id="customUrl"
              value={customUrl}
              readOnly
              className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-100"
            />
          </div>
        ) : (
          <>
            <div>
              <label htmlFor="smartUrl" className="block text-sm font-medium text-gray-700 mb-1">Smart URL</label>
              <input
                type="text"
                id="smartUrl"
                value={smartUrl}
                readOnly
                className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-100"
              />
            </div>
            <div>
              <label htmlFor="customUrl" className="block text-sm font-medium text-gray-700 mb-1">
                Custom URL (optional)
              </label>
              <input
                type="text"
                id="customUrl"
                value={customUrl}
                onChange={handleCustomUrlChange}
                placeholder="Enter a custom URL or leave blank to use Smart URL"
                className={`w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${urlError ? 'border-red-500' : ''}`}
              />
              {urlError && <p className="mt-1 text-sm text-red-500">{urlError}</p>}
            </div>
          </>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
              Start Date and Time {renderRequiredAsterisk()}
            </label>
            <div className="flex space-x-2">
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={event.startDate}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="time"
                id="startTime"
                name="startTime"
                value={event.startTime}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
          <div>
            <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
              End Date and Time {renderRequiredAsterisk()}
            </label>
            <div className="flex space-x-2">
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={event.endDate}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="time"
                id="endTime"
                name="endTime"
                value={event.endTime}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>
        {dateTimeError && (
          <p className="text-red-500 text-sm">{dateTimeError}</p>
        )}
        <div>
          <label htmlFor="timezone" className="block text-sm font-medium text-gray-700 mb-1">
            Timezone {renderRequiredAsterisk()}
          </label>
          <select
            id="timezone"
            name="timezone"
            value={showCustomTimezone ? 'other' : event.timezone}
            onChange={handleTimezoneChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {timezoneOptions.map(({ value, label }) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
          {showCustomTimezone && (
            <select
              id="customTimezone"
              name="customTimezone"
              value={event.timezone}
              onChange={(e) => setEvent(prevEvent => ({ ...prevEvent, timezone: e.target.value }))}
              required
              className="mt-2 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {Intl.supportedValuesOf('timeZone').map((tz) => (
                <option key={tz} value={tz}>{tz}</option>
              ))}
            </select>
          )}
        </div>
        <div>
          <label htmlFor="locationName" className="block text-sm font-medium text-gray-700 mb-1">
            Location Name
          </label>
          <input
            type="text"
            id="locationName"
            name="locationName"
            value={event.locationName}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="e.g. Our House, Central Park, etc."
          />
        </div>
        <div>
          <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
            Location Address {renderRequiredAsterisk()}
          </label>
          <input
            type="text"
            id="location"
            name="location"
            value={event.location}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Full address for Google Maps"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
            Description
          </label>
          <CKEditor
            editor={ClassicEditor}
            data={event.description}
            onChange={handleDescriptionChange}
            config={{
              link: {
                addTargetToExternalLinks: true,
              },
            }}
          />
        </div>
        <div>
          <label htmlFor="image" className="block text-sm font-medium text-gray-700 mb-1">Image</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleImageChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          {event.imageUrl && !removeImage && (
            <div className="mt-2">
              <img src={event.imageUrl} alt="Event" className="max-w-full max-h-40" />
              <button
                type="button"
                onClick={() => setRemoveImage(true)}
                className="mt-2 text-red-500 hover:underline"
              >
                Remove Image
              </button>
            </div>
          )}
          {removeImage && (
            <p className="mt-2 text-sm text-gray-500">
              Image removed. The event name will be displayed instead.
            </p>
          )}
        </div>
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="requiresRSVP"
            name="requiresRSVP"
            checked={event.requiresRSVP}
            onChange={handleChange}
            className="mr-2 h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label htmlFor="requiresRSVP" className="text-sm font-medium text-gray-700">Enable RSVPs</label>
        </div>
        <div className="flex flex-col mb-4">
          <div className="flex items-center mb-1">
            <input
              type="checkbox"
              id="isPrivate"
              name="isPrivate"
              checked={event.isPrivate}
              onChange={(e) => {
                setEvent({ ...event, isPrivate: e.target.checked });
                if (!e.target.checked) {
                  // Clear selected groups when event is made public
                  setSelectedGroupIds([]);
                }
              }}
              className="mr-2 h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="isPrivate" className="text-sm font-medium text-gray-700">Make this event private</label>
          </div>
          <p className="text-xs text-gray-500 ml-7">
            Private events won't be listed publicly and can only be shared with specific groups or via direct link.
          </p>
        </div>
        {event.isPrivate && (
          <div className="mb-6">
            <label htmlFor="groups" className="block text-sm font-medium text-gray-700 mb-2">
              Associate with Groups
            </label>
            <p className="text-xs text-gray-500 mb-2">
              Groups allow you to easily share this private event with specific friends.
            </p>
            <div className="flex flex-col space-y-2">
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  placeholder="Search groups..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(1);
                  }}
                  className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="button"
                  onClick={() => setIsCreateGroupModalOpen(true)}
                  className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 whitespace-nowrap text-sm"
                >
                  New Group
                </button>
              </div>
              <div className="border border-gray-300 rounded-md">
                {currentGroups.length > 0 ? (
                  <div className="max-h-60 overflow-y-auto">
                    {currentGroups.map(group => (
                      <div
                        key={group.id}
                        onClick={() => toggleGroupSelection(group.id)}
                        className={`flex items-center p-2 cursor-pointer hover:bg-gray-100 ${
                          selectedGroupIds.includes(group.id) ? 'bg-blue-100' : ''
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={selectedGroupIds.includes(group.id)}
                          onChange={() => {}}
                          className="mr-2 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                        <span className="text-sm">{group.name}</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="p-2 text-gray-500 text-sm">No groups found</p>
                )}
              </div>
              {pageCount > 1 && (
                <div className="flex justify-between items-center mt-2">
                  <button
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className="px-2 py-1 bg-gray-200 rounded-md text-sm disabled:opacity-50"
                  >
                    Previous
                  </button>
                  <span className="text-sm">
                    Page {currentPage} of {pageCount}
                  </span>
                  <button
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount))}
                    disabled={currentPage === pageCount}
                    className="px-2 py-1 bg-gray-200 rounded-md text-sm disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="flex flex-col space-y-4">
          {isEditMode ? (
            event.requiresRSVP ? (
              <>
                <button 
                  type="submit" 
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300"
                >
                  Update Event and Notify Attendees
                </button>
                <button 
                  type="button"
                  onClick={(e) => handleSubmit(e, false)}
                  className="w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition duration-300"
                >
                  Update Event Without Notifying
                </button>
              </>
            ) : (
              <button 
                type="submit" 
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300"
              >
                Update Event
              </button>
            )
          ) : (
            <button 
              type="submit" 
              className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300"
            >
              Create Event
            </button>
          )}
          <button 
            type="button" 
            onClick={handleCancel} 
            className="w-full bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition duration-300"
          >
            Cancel
          </button>
        </div>
      </form>
      {showRSVPWarning && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg shadow-xl">
            <h2 className="text-xl font-bold mb-4">Warning</h2>
            <p className="mb-4">Unchecking this will remove the RSVP section and delete all existing RSVPs for this event. Are you sure you want to proceed?</p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowRSVPWarning(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleRSVPWarningConfirm}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      <p className="mt-4 text-sm text-gray-600">
        Fields marked with {renderRequiredAsterisk()} are required.
      </p>
      <CreateGroupModal
        isOpen={isCreateGroupModalOpen}
        onClose={() => setIsCreateGroupModalOpen(false)}
        onGroupCreated={handleGroupCreated}
      />
      <ConfirmationModal
        isOpen={showUnsavedChangesModal}
        onClose={() => setShowUnsavedChangesModal(false)}
        onConfirm={handleConfirmClose}
        message="You have unsaved changes. Are you sure you want to close this form?"
      />
    </div>
  );
};

export default EventFormManager;
