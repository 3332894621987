import { db } from '../firebase';
import { doc, runTransaction } from 'firebase/firestore';

export const updateGroupEventRelationship = async (eventId, groupId, isAdding) => {
  const eventRef = doc(db, 'events', eventId);
  const groupRef = doc(db, 'groups', groupId);

  try {
    await runTransaction(db, async (transaction) => {
      const eventDoc = await transaction.get(eventRef);
      const groupDoc = await transaction.get(groupRef);

      if (!eventDoc.exists() || !groupDoc.exists()) {
        throw new Error("Document does not exist!");
      }

      const eventData = eventDoc.data();
      const groupData = groupDoc.data();

      let updatedEventGroupIds = eventData.groupIds || [];
      let updatedGroupEventIds = groupData.eventIds || [];

      if (isAdding) {
        updatedEventGroupIds = [...new Set([...updatedEventGroupIds, groupId])];
        updatedGroupEventIds = [...new Set([...updatedGroupEventIds, eventId])];
      } else {
        updatedEventGroupIds = updatedEventGroupIds.filter(id => id !== groupId);
        updatedGroupEventIds = updatedGroupEventIds.filter(id => id !== eventId);
      }

      transaction.update(eventRef, { groupIds: updatedEventGroupIds });
      transaction.update(groupRef, { eventIds: updatedGroupEventIds });
    });
    console.log("Relationship update successful");
    return true;
  } catch (error) {
    console.error("Error updating relationship:", error);
    return false;
  }
};
