import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { generateICSLink } from '../events/eventActions';
import { getEventByUrl } from '../events/eventServices';

const ICSFileDownload = () => {
  const { eventUrl } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEventAndDownloadICS = async () => {
      try {
        const event = await getEventByUrl(eventUrl);
        if (event) {
          const icsFileLink = generateICSLink(event);
          if (icsFileLink) {
            const link = document.createElement('a');
            link.href = icsFileLink;
            link.download = `${event.name.replace(/\s+/g, '_')}.ics`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(icsFileLink);
          } else {
            console.error('Failed to generate ICS file');
          }
        } else {
          console.error('Event not found');
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      } finally {
        // Redirect back to the event page
        navigate(`/event/${eventUrl}`);
      }
    };

    fetchEventAndDownloadICS();
  }, [eventUrl, navigate]);

  return <div>Downloading ICS file...</div>;
};

export default ICSFileDownload;
