import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, query, where, deleteDoc, doc } from 'firebase/firestore'; 
import { db } from '../firebase';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../Auth';
import { useNotification } from '../components/NotificationContext';
import ConfirmationModal from '../components/ConfirmationModal';
import EventCard from './EventCard';

const EventList = () => {
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated } = useAuth();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [allGroups, setAllGroups] = useState([]);
  
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilters, setActiveFilters] = useState({
    myEvents: false,
    rsvpd: false,
    private: false,
    groups: [], // Ensure this is an empty array, not undefined
    timeFrame: 'all'
  });
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);

  const filterRef = useRef(null);

  useEffect(() => {
    fetchEvents();
  }, [user]);

  useEffect(() => {
    filterAndSortEvents();
  }, [events, activeFilters, sortOrder, searchTerm, selectedGroups]);

  useEffect(() => {
    if (isFilterExpanded && filterRef.current) {
      const filterHeight = filterRef.current.offsetHeight;
      document.documentElement.style.setProperty('--filter-height', `${filterHeight}px`);
    } else {
      document.documentElement.style.setProperty('--filter-height', '0px');
    }
  }, [isFilterExpanded]);

  const fetchEvents = async () => {
    setLoading(true);
    try {
      console.log("Fetching events...");
      const eventsQuery = collection(db, 'events');
      const eventSnapshot = await getDocs(eventsQuery);

      let eventList = eventSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        isMyEvent: isAuthenticated && doc.data().createdBy === user.uid,
        isRSVPd: false,
        rsvpId: null
      }));

      if (isAuthenticated) {
        // Fetch all groups
        const allGroupsQuery = query(collection(db, 'groups'));
        const allGroupsSnapshot = await getDocs(allGroupsQuery);
      
        const userGroups = allGroupsSnapshot.docs
          .filter(doc => doc.data().members.some(member => member.uid === user.uid))
          .map(doc => ({
            id: doc.id,
            name: doc.data().name,
            eventIds: doc.data().eventIds || []
          }));
      
        console.log("User groups:", userGroups);
        setAllGroups(userGroups);  // Move this line here

        // Fetch user's RSVPs
        const rsvpsQuery = query(collection(db, 'rsvps'), where('userId', '==', user.uid));
        const rsvpSnapshot = await getDocs(rsvpsQuery);
        const rsvps = rsvpSnapshot.docs.map((doc) => ({
          rsvpId: doc.id,
          eventId: doc.data().eventId,
        }));
        console.log("User's RSVPs:", rsvps);

        // Update RSVP status for each event
        eventList = eventList.map((event) => {
          const rsvp = rsvps.find(rsvp => rsvp.eventId === event.id);
          const isRSVPd = !!rsvp;
          const rsvpId = rsvp ? rsvp.rsvpId : null;
          console.log(`Event ${event.id} RSVP status:`, { isRSVPd, rsvpId });
          return {
            ...event,
            isRSVPd,
            rsvpId
          };
        });
      }

      console.log("All events:", eventList);

      // Filter out private events for non-authenticated users
      if (!isAuthenticated) {
        eventList = eventList.filter(event => !event.isPrivate);
      } else {
        console.log("User is authenticated, UID:", user.uid);
        // Fetch user's RSVPs
        const rsvpsQuery = query(collection(db, 'rsvps'), where('email', '==', user.email));
        const rsvpSnapshot = await getDocs(rsvpsQuery);
        const rsvps = rsvpSnapshot.docs.map((doc) => ({
          rsvpId: doc.id,
          eventId: doc.data().eventId,
        }));
        console.log("User RSVPs:", rsvps);

        // Fetch all groups
        console.log("Fetching all groups");
        const allGroupsQuery = query(collection(db, 'groups'));
        const allGroupsSnapshot = await getDocs(allGroupsQuery);
        console.log("Total groups in database:", allGroupsSnapshot.size);

        const userGroups = allGroupsSnapshot.docs
          .filter(doc => doc.data().members.some(member => member.uid === user.uid))
          .map(doc => ({
            id: doc.id,
            name: doc.data().name,
            eventIds: doc.data().eventIds || []
          }));

        console.log("User groups:", userGroups);

        // Filter events for authenticated users
        eventList = eventList.filter(event => {
          const isPublic = !event.isPrivate;
          const isCreator = event.isMyEvent;
          const hasRSVP = rsvps.some(rsvp => rsvp.eventId === event.id);
          const isMemberOfGroup = userGroups.some(group => group.eventIds && group.eventIds.includes(event.id));
          
          return isPublic || isCreator || hasRSVP || isMemberOfGroup;
        });

        // Update RSVP status and group names
        eventList = eventList.map((event) => {
          const eventGroups = userGroups.filter(group => group.eventIds && group.eventIds.includes(event.id));
          return {
            ...event,
            rsvpId: rsvps.find((rsvp) => rsvp.eventId === event.id)?.rsvpId || null,
            groupIds: eventGroups.map(group => group.id),
            groupNames: eventGroups.map(group => group.name)
          };
        });
      }

      console.log("Final event list with RSVP status:", eventList.map(e => ({ id: e.id, isRSVPd: e.isRSVPd, rsvpId: e.rsvpId })));
      setEvents(eventList);

      // Remove this line:
      // setAllGroups(userGroups);

    } catch (error) {
      console.error('Error fetching events:', error);
      showNotification('Error fetching events: ' + error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const filterAndSortEvents = () => {
    let filtered = [...events];

    if (activeFilters.myEvents) {
      filtered = filtered.filter((event) => event.isMyEvent);
    }

    if (activeFilters.rsvpd) {
      filtered = filtered.filter((event) => event.rsvpId);
    }

    if (activeFilters.private) {
      filtered = filtered.filter((event) => event.isPrivate);
    }

    if (activeFilters.groups.length > 0) {
      filtered = filtered.filter(event => 
        event.groupIds && event.groupIds.some(groupId => activeFilters.groups.includes(groupId))
      );
    }

    // Apply time frame filter
    const now = new Date();
    switch (activeFilters.timeFrame) {
      case 'upcoming':
        filtered = filtered.filter(event => {
          const eventStart = new Date(`${event.startDate}T${event.startTime}`);
          return eventStart > now;
        });
        break;
      case 'past':
        filtered = filtered.filter(event => {
          const eventEnd = new Date(`${event.endDate}T${event.endTime || '23:59'}`);
          return eventEnd < now;
        });
        break;
      // 'all' doesn't need filtering
    }

    // Apply search
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter(event =>
        event.name.toLowerCase().includes(lowerSearchTerm) ||
        event.description.toLowerCase().includes(lowerSearchTerm)
      );
    }

    // Apply sorting
    filtered.sort((a, b) => {
      const dateA = new Date(`${a.startDate}T${a.startTime}`);
      const dateB = new Date(`${b.startDate}T${b.startTime}`);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    setFilteredEvents(filtered);
  };

  const handleDelete = (eventId) => {
    setEventToDelete(eventId);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    if (!eventToDelete) return;

    try {
      await deleteDoc(doc(db, 'events', eventToDelete));
      showNotification('Event deleted successfully', 'success');
      setEvents(events.filter(event => event.id !== eventToDelete));
    } catch (error) {
      console.error('Error deleting event: ', error);
      showNotification('Error deleting event: ' + error.message, 'error');
    } finally {
      setIsDeleteModalOpen(false);
      setEventToDelete(null);
    }
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const handleFilterChange = (filterName, value) => {
    setActiveFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
  };

  const handleEventSaved = (updatedEvent) => {
    setEvents(prevEvents => prevEvents.map(event => 
      event.id === updatedEvent.id ? updatedEvent : event
    ));
    showNotification('Event updated successfully', 'success');
  };

  if (loading) {
    return <div className="text-center py-4 mt-16">Loading events...</div>;
  }

  return (
    <div className="bg-gray-100 min-h-screen relative pb-16 md:pb-0">
      <div className="bg-blue-100 py-4 sticky top-16" style={{ zIndex: 'var(--z-fixed-element)' }}>
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-center">
            <div className="w-full md:w-1/2 relative">
              <input
                type="text"
                placeholder="Search events..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-4 py-2 pr-10 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={() => setIsFilterExpanded(!isFilterExpanded)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
              >
                <i className="fas fa-filter"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      
      {isFilterExpanded && (
        <div ref={filterRef} className="bg-white py-4 shadow-md fixed left-0 right-0" style={{ top: 'calc(var(--header-height) + 64px)', zIndex: 'calc(var(--z-fixed-element) - 1)' }}>
          <div className="container mx-auto px-4 flex justify-center">
            <div className="w-full md:w-1/2">
              <FilterSection
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                allGroups={allGroups}
                handleFilterChange={handleFilterChange}
              />
            </div>
          </div>
        </div>
      )}

      <div className="container mx-auto px-4 py-8 relative" style={{ zIndex: 'var(--z-content)', marginTop: `calc(var(--filter-height) + 16px)` }}>
        {filteredEvents.length === 0 ? (
          <div className="text-center py-8 bg-white shadow-md">
            <p className="text-xl text-gray-600">No events match your current search or filters.</p>
            <p className="text-gray-500 mt-2">Try adjusting your search term or filter selections, or check back later for new events.</p>
            {!isAuthenticated && (
              <div className="mt-4">
                <Link to="/login" className="inline-block px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300 ease-in-out">Sign in to create your own events</Link>
              </div>
            )}
          </div>
        ) : (
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {filteredEvents.map((event) => (
              <EventCard
                key={event.id}
                event={{
                  ...event,
                  isMyEvent: event.isMyEvent,
                  isRSVPd: event.isRSVPd,
                  rsvpId: event.rsvpId
                }}
                mode="list"
                onEdit={handleEventSaved}
                onDelete={handleDelete}
                onFilterChange={handleFilterChange}
                activeFilters={activeFilters}
              />
            ))}
          </div>
        )}
      </div>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={confirmDelete}
        message="Are you sure you want to delete this event?"
      />
    </div>
  );
};

const FilterSection = ({ 
  activeFilters, 
  setActiveFilters, 
  sortOrder, 
  setSortOrder, 
  allGroups,
  handleFilterChange
}) => {
  const [isGroupDropdownOpen, setIsGroupDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && 
          buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsGroupDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleGroupButtonClick = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      document.documentElement.style.setProperty('--button-bottom', `${buttonRect.bottom}px`);
    }
    setIsGroupDropdownOpen(!isGroupDropdownOpen);
  };

  const { isAuthenticated } = useAuth(); // Add this line to get authentication status

  return (
    <div>
      <div className="flex flex-wrap items-center gap-2">
        <button
          onClick={() => setActiveFilters(prev => ({ ...prev, timeFrame: 'all' }))}
          className={`px-3 py-1 text-sm rounded-full ${activeFilters.timeFrame === 'all' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
        >
          All
        </button>
        <button
          onClick={() => setActiveFilters(prev => ({ ...prev, timeFrame: 'upcoming' }))}
          className={`px-3 py-1 text-sm rounded-full ${activeFilters.timeFrame === 'upcoming' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
        >
          Upcoming
        </button>
        <button
          onClick={() => setActiveFilters(prev => ({ ...prev, timeFrame: 'past' }))}
          className={`px-3 py-1 text-sm rounded-full ${activeFilters.timeFrame === 'past' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
        >
          Past
        </button>
        {isAuthenticated && (
        <button
          onClick={() => setActiveFilters(prev => ({ ...prev, myEvents: !prev.myEvents }))}
          className={`px-3 py-1 text-sm rounded-full transition-colors duration-200 ${
            activeFilters.myEvents ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
          Created
        </button>
        )}
        {isAuthenticated && (
        <button
          onClick={() => setActiveFilters(prev => ({ ...prev, rsvpd: !prev.rsvpd }))}
          className={`px-3 py-1 text-sm rounded-full transition-colors duration-200 ${
            activeFilters.rsvpd ? 'bg-green-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
          RSVP'd
        </button>
        )}
        {isAuthenticated && (
          <button
            onClick={() => handleFilterChange('private', !activeFilters.private)}
            className={`px-3 py-1 text-sm rounded-full transition-colors duration-200 ${
              activeFilters.private ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Private
          </button>
        )}
        
        {isAuthenticated && (
          <div className="relative group">
            <button
              ref={buttonRef}
              onClick={handleGroupButtonClick}
              className={`px-3 py-1 text-sm rounded-full transition-colors duration-200 ${
                activeFilters.groups.length > 0 ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              Groups {activeFilters.groups.length > 0 && `(${activeFilters.groups.length})`}
            </button>
            {isGroupDropdownOpen && (
              <div 
                ref={dropdownRef}
                className="absolute z-10 mt-1 w-48 bg-white rounded-md shadow-lg transform -translate-x-1/2 left-1/2"
              >
                <div className="max-h-60 overflow-y-auto">
                  {allGroups.map((group) => (
                    <button
                      key={group.id}
                      onClick={() => {
                        handleFilterChange('groups', 
                          activeFilters.groups.includes(group.id)
                            ? activeFilters.groups.filter(id => id !== group.id)
                            : [...activeFilters.groups, group.id]
                        );
                        // Dropdown remains open after selection
                      }}
                      className={`block w-full text-left px-4 py-2 text-sm ${
                        activeFilters.groups.includes(group.id) ? 'bg-blue-100 text-blue-800' : 'text-gray-700 hover:bg-gray-100'
                      }`}
                    >
                      {group.name}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        <button
          onClick={() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}
          className="px-3 py-1 text-sm rounded-full bg-gray-200 text-gray-700"
        >
          {sortOrder === 'asc' ? 'Oldest First' : 'Newest First'}
          <span className="ml-1">
            {sortOrder === 'asc' ? '↑' : '↓'}
          </span>
        </button>
      
      <button
        onClick={() => {
          setActiveFilters({
            myEvents: false,
            rsvpd: false,
            private: false,
            groups: [],
            timeFrame: 'all'
          });
          setSortOrder('desc');
        }}
        className="px-3 py-1 text-sm rounded-full bg-red-500 text-white hover:bg-red-600"
      >
        Reset
      </button>
      </div>
    </div>
  );
};

export default EventList;
