import React, { useState } from 'react';
import { handleCopyLink, handleShareEmail, generateICSLink } from '../events/eventActions';
import { useNotification } from '../components/NotificationContext';
import { useAuth } from '../Auth';
import EmailShareModal from '../emails/EmailShareModal';

const ShareButtons = ({ event }) => {
  const { showNotification } = useNotification();
  const { user } = useAuth();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const eventUrl = `${window.location.origin}/event/${event.url}`;

  const handleAddToCalendar = () => {
    const icsFileLink = generateICSLink(event);
    if (icsFileLink) {
      const link = document.createElement('a');
      link.href = icsFileLink;
      link.download = `${event.name.replace(/\s+/g, '_')}.ics`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(icsFileLink);
    } else {
      showNotification('Failed to generate calendar file', 'error');
    }
  };

  const handleEmailShare = async (email) => {
    console.log('Sharing email for event:', { event, eventUrl, email });
    try {
      await handleShareEmail(event, eventUrl, email);
      setIsEmailModalOpen(false);
      showNotification('Event details sent successfully', 'success');
    } catch (error) {
      console.error('Error sharing email:', error);
      showNotification('Failed to send email: ' + error.message, 'error');
    }
  };

  return (
    <>
      <div className="flex space-x-2 relative">
        <button
          className="bg-gray-600 text-white w-8 h-8 flex items-center justify-center rounded-full hover:bg-opacity-100 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={handleAddToCalendar}
          title="Add to Calendar"
        >
          <i className="fas fa-calendar-plus"></i>
        </button>
        <button
          className="bg-gray-600 text-white w-8 h-8 flex items-center justify-center rounded-full hover:bg-opacity-100 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => setIsEmailModalOpen(true)}
          title="Share via Email"
        >
          <i className="fas fa-envelope"></i>
        </button>
        <button
          className="bg-gray-600 text-white w-8 h-8 flex items-center justify-center rounded-full hover:bg-opacity-100 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => handleCopyLink(eventUrl, showNotification)}
          title="Copy Link"
        >
          <i className="fas fa-link"></i>
        </button>
      </div>
      {isEmailModalOpen && (
        <EmailShareModal
          isOpen={isEmailModalOpen}
          onClose={() => setIsEmailModalOpen(false)}
          onSend={handleEmailShare}
          userEmail={user?.email}
        />
      )}
    </>
  );
};

export default ShareButtons;
