import React from 'react';
import EventFormManager from './EventFormManager';
import ModalPortal from '../components/ModalPortal';

const EditEventModal = ({ isOpen, onClose, event, onEventSaved }) => {
  if (!isOpen) return null;

  const handleEventSaved = (updatedEvent) => {
    if (onEventSaved) {
      onEventSaved(updatedEvent);
    }
    onClose();
  };

  return (
    <ModalPortal>
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" style={{ zIndex: 9999 }}>
        <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white">
          <button onClick={onClose} className="absolute top-0 right-0 mt-4 mr-4 text-gray-400 hover:text-gray-500">
            <span className="sr-only">Close</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <EventFormManager 
            isModal={true} 
            onClose={onClose} 
            initialEvent={{ ...event, groupIds: event.groupIds }}
            onEventSaved={handleEventSaved}
          />
        </div>
      </div>
    </ModalPortal>
  );
};

export default EditEventModal;
