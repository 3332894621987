import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { createTestUserDocument } from './userManagement';
import { PendingActionContext } from '../App';
import { useAuth } from '../Auth';
import { useNotification } from '../components/NotificationContext';
import TestEmailButton from '../emails/TestEmailButton';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [newTestEmail, setNewTestEmail] = useState('');
  const [testName, setTestName] = useState('');
  const [loginTestEmail, setLoginTestEmail] = useState('');
  const [useMockSignIn, setUseMockSignIn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login, setUser } = useAuth();
  const { pendingAction, setPendingAction } = useContext(PendingActionContext);
  const { showNotification } = useNotification();

  const DEFAULT_TEST_PASSWORD = 'TestAccount123!';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const result = await login(email, useMockSignIn);
    setIsLoading(false);
    if (result.success) {
      showNotification(result.message, 'success');
      if (result.user) {
        handlePostLoginNavigation(result.user);
      } else {
        showNotification('Login successful, but there was an issue. Please try again.', 'warning');
      }
    } else {
      showNotification(result.message, 'error');
    }
  };

  const handleCreateTestUser = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, newTestEmail, DEFAULT_TEST_PASSWORD);
      await updateProfile(userCredential.user, { displayName: testName });
      const newUser = await createTestUserDocument(newTestEmail, testName);
      setUser(newUser);
      showNotification('Test user created and logged in successfully!', 'success');
      handlePostLoginNavigation(newUser);
    } catch (error) {
      showNotification('Error creating test user: ' + error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTestLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await login(loginTestEmail, true);
      if (result.success) {
        showNotification('You have successfully signed in with a test account!', 'success');
        handlePostLoginNavigation(result.user);
      } else {
        showNotification(result.message, 'error');
      }
    } catch (error) {
      showNotification('Error signing in with test account: ' + error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePostLoginNavigation = (user) => {
    if (pendingAction && pendingAction.type === 'RSVP') {
      navigate(`/event/${pendingAction.eventUrl}`);
      setPendingAction(null);
    } else {
      navigate('/my-events');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-extrabold text-center text-gray-900 mb-8">Admin Login</h1>
        
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="text-lg leading-6 font-medium text-gray-900 mb-4">Regular Login</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="useMockSignIn"
                  checked={useMockSignIn}
                  onChange={(e) => setUseMockSignIn(e.target.checked)}
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor="useMockSignIn" className="ml-2 block text-sm text-gray-900">
                  Use Mock Sign-In
                </label>
              </div>
              <button 
                type="submit" 
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : 'Login / Create Account'}
              </button>
            </form>
          </div>
        </div>

        <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="text-lg leading-6 font-medium text-gray-900 mb-4">Create New Test Account</h2>
            <form onSubmit={handleCreateTestUser} className="space-y-4">
              <div>
                <label htmlFor="testName" className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  id="testName"
                  value={testName}
                  onChange={(e) => setTestName(e.target.value)}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="newTestEmail" className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  id="newTestEmail"
                  value={newTestEmail}
                  onChange={(e) => setNewTestEmail(e.target.value)}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                Create Test Account
              </button>
            </form>
          </div>
        </div>

        <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="text-lg leading-6 font-medium text-gray-900 mb-4">Login to Existing Test Account</h2>
            <form onSubmit={handleTestLogin} className="space-y-4">
              <div>
                <label htmlFor="loginTestEmail" className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  id="loginTestEmail"
                  value={loginTestEmail}
                  onChange={(e) => setLoginTestEmail(e.target.value)}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Login to Test Account
              </button>
            </form>
          </div>
        </div>

        <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="text-lg leading-6 font-medium text-gray-900 mb-4">Send Test Email</h2>
            <TestEmailButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
