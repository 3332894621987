import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../Auth';
import { getGroupsByUser, joinGroupByCode } from './groupServices';
import CreateGroupModal from './CreateGroupModal';
import { useNotification } from '../components/NotificationContext';

const MyGroups = () => {
  const [groups, setGroups] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [groupCodeToJoin, setGroupCodeToJoin] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('myGroups');
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const [createdGroupsCount, setCreatedGroupsCount] = useState(0);
  const [joinedGroupsCount, setJoinedGroupsCount] = useState(0);

  useEffect(() => {
    fetchGroups();
  }, [user]);

  const fetchGroups = async () => {
    if (user) {
      setIsLoading(true);
      setError(null);
      try {
        const fetchedGroups = await getGroupsByUser(user.uid);
        setGroups(fetchedGroups);
        setCreatedGroupsCount(fetchedGroups.filter(group => group.isOwner).length);
        setJoinedGroupsCount(fetchedGroups.filter(group => !group.isOwner).length);
      } catch (error) {
        console.error('Error fetching groups:', error);
        setError('Failed to fetch groups. Please try again later.');
        showNotification('Error fetching groups', 'error');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleGroupCreated = (newGroup) => {
    setGroups(prevGroups => [...prevGroups, newGroup]);
    setIsCreateModalOpen(false);
    showNotification('Group created successfully', 'success');
  };

  const handleJoinGroup = async () => {
    if (!groupCodeToJoin.trim()) {
      showNotification('Please enter a group code', 'error');
      return;
    }

    try {
      const joinedGroup = await joinGroupByCode(groupCodeToJoin, user);
      if (joinedGroup) {
        setGroups(prevGroups => [...prevGroups, joinedGroup]);
        setGroupCodeToJoin('');
        showNotification('Successfully joined the group', 'success');
      }
    } catch (error) {
      showNotification('Error joining group: ' + error.message, 'error');
    }
  };

  const copyGroupCode = (groupCode, e) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(groupCode).then(() => {
      showNotification('Group code copied to clipboard', 'success');
    }, (err) => {
      console.error('Could not copy text: ', err);
      showNotification('Failed to copy group code', 'error');
    });
  };

  const renderGroupCard = (group) => (
    <div key={group.id} className="mb-4 w-full">
      <div className="bg-gradient-to-r from-blue-500 to-blue-600 w-full">
        <div className="w-full mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <Link to={`/group/${group.url}`} className="text-lg font-semibold text-white hover:text-blue-200">
              {group.name}
            </Link>
            <div 
              className="bg-blue-700 text-white text-xs font-medium px-3 py-1 rounded-full items-center cursor-pointer"
              onClick={(e) => copyGroupCode(group.groupCode, e)}
            >
              <span className="mr-2">Code: {group.groupCode}</span>
              <i className="fas fa-copy"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white shadow-md">
        <div className="block p-4">
          <p className="text-gray-600 mb-4 line-clamp-2">{group.description}</p>
          <div className="flex justify-between items-center">
            <span className="text-sm text-gray-600">
              {group.members.length} {group.members.length === 1 ? 'member' : 'members'}
            </span>
            <span className="text-sm text-gray-600">
              {group.eventIds?.length || 0} {(group.eventIds?.length || 0) === 1 ? 'event' : 'events'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const myGroups = groups.filter(group => group.isOwner);
  const joinedGroups = groups.filter(group => !group.isOwner);

  return (
    <div className="w-full">
      <div className="bg-gray-100 border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold py-6">My Groups</h1>
          <div className="flex">
            <button
              onClick={() => setActiveTab('myGroups')}
              className={`py-3 px-4 text-center font-medium border-b-2 ${
                activeTab === 'myGroups'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Created Groups ({createdGroupsCount})
            </button>
            <button
              onClick={() => setActiveTab('joinedGroups')}
              className={`py-3 px-4 text-center font-medium border-b-2 ${
                activeTab === 'joinedGroups'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Joined Groups ({joinedGroupsCount})
            </button>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {isLoading ? (
          <p className="text-center text-gray-600">Loading groups...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : (
          <div>
            {activeTab === 'myGroups' && (
              <div>
                <div className="flex justify-between items-center mb-4">
                  <button
                    onClick={() => setIsCreateModalOpen(true)}
                    className="bg-green-600 text-white px-4 py-2 rounded-full hover:bg-green-500 transition duration-300"
                  >
                    Create New Group
                  </button>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {myGroups.length > 0 ? (
                    myGroups.map(renderGroupCard)
                  ) : (
                    <p className="text-gray-500 italic col-span-full">You haven't created any groups yet.</p>
                  )}
                </div>
              </div>
            )}

            {activeTab === 'joinedGroups' && (
              <div>
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
                  <div className="flex w-full sm:w-auto">
                    <input
                      type="text"
                      value={groupCodeToJoin}
                      onChange={(e) => setGroupCodeToJoin(e.target.value)}
                      placeholder="Group code"
                      maxLength={15}
                      className="border rounded-l-full px-3 py-2 w-32 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                    />
                    <button
                      onClick={handleJoinGroup}
                      className="bg-blue-500 text-white px-3 py-2 rounded-r-full hover:bg-blue-600 transition duration-300 text-sm whitespace-nowrap"
                    >
                      Join New Group
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {joinedGroups.length > 0 ? (
                    joinedGroups.map(renderGroupCard)
                  ) : (
                    <p className="text-gray-500 italic col-span-full">You haven't joined any groups yet.</p>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {isCreateModalOpen && (
          <CreateGroupModal
            isOpen={isCreateModalOpen}
            onClose={() => setIsCreateModalOpen(false)}
            onGroupCreated={handleGroupCreated}
          />
        )}
      </div>
    </div>
  );
};

export default MyGroups;
