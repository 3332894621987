import React, { useState, useEffect } from 'react';
import { useAuth } from '../Auth';
import { useNotification } from '../components/NotificationContext';
import { handleRSVP, removeRSVP, getRSVPs, checkRSVP } from './rsvpService';
import { sendEventDetailEmail } from '../emails/emailService';
import UserAvatar from '../users/UserAvatar';
import { Link } from 'react-router-dom';

const RSVPManager = ({ event, onRSVPChange, message }) => {
  const [rsvpList, setRsvpList] = useState([]);
  const [isRSVPd, setIsRSVPd] = useState(false);
  const [guestEmail, setGuestEmail] = useState('');
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [rsvpMessage, setRsvpMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const { user, refreshUser, setUser } = useAuth();
  const { showNotification } = useNotification();
  const [refreshKey, setRefreshKey] = useState(0);
  const [showGuestMessage, setShowGuestMessage] = useState(true);

  const isEventCreator = user && (event.createdBy === user.uid || event.createdBy === user.email);
  const isPastEvent = new Date(`${event.endDate}T${event.endTime || '23:59'}`) < new Date();

  useEffect(() => {
    fetchRSVPs();
    if (user) {
      checkUserRSVP();
    }
  }, [event.id, user, refreshKey]); // Add refreshKey here

  useEffect(() => {
    if (user && !user.isGuest) {
      setShowGuestMessage(false);
    }
  }, [user]);

  const fetchRSVPs = async () => {
    console.log('Fetching RSVPs in RSVPManager');
    try {
      const rsvps = await getRSVPs(event.id);
      console.log('Fetched RSVPs:', rsvps);
      // Sort RSVPs to put the current user's RSVP at the top
      const sortedRsvps = rsvps.sort((a, b) => {
        if (user && a.email === user.email) return -1;
        if (user && b.email === user.email) return 1;
        return 0;
      });
      setRsvpList(sortedRsvps);
    } catch (error) {
      console.error('Error fetching RSVPs:', error);
      showNotification('Error fetching RSVPs', 'error');
    }
  };

  const checkUserRSVP = async () => {
    if (user) {
      try {
        const rsvpStatus = await checkRSVP(event.id, user.email);
        setIsRSVPd(rsvpStatus);
        if (onRSVPChange) {
          onRSVPChange(rsvpStatus);
        }
      } catch (error) {
        console.error('Error checking user RSVP:', error);
        showNotification('Error checking RSVP status', 'error');
      }
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleRSVPAction = async () => {
    if (isPastEvent) return;

    if (!user && !validateEmail(guestEmail)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    try {
      const email = user ? user.email : guestEmail;
      const result = await handleRSVP(email, event.id, !!user);
      
      setIsEmailSubmitted(true);
      setIsRSVPd(result.isRSVPd);
      setRsvpMessage(result.message);
      showNotification(result.message, result.wasAlreadyRSVPd ? 'info' : 'success');
      
      if (onRSVPChange) {
        onRSVPChange(result.isRSVPd);
      }
      
      if (result.isNewUser && result.user) {
        setUser(result.user);
      } else if (result.isExistingUser) {
        await refreshUser();
      }
      fetchRSVPs();

      // Remove the email sending code from here
    } catch (error) {
      console.error('Error handling RSVP:', error);
      showNotification('Error handling RSVP: ' + error.message, 'error');
    }
  };

  const handleRemoveRSVP = async (emailToRemove) => {
    if (isPastEvent) return;

    try {
      const result = await removeRSVP(event.id, emailToRemove);
      if (result.success) {
        showNotification('RSVP has been removed.', 'success');
        setRefreshKey(prevKey => prevKey + 1); // This will trigger a re-render
        fetchRSVPs();
        if (emailToRemove === user?.email) {
          setIsRSVPd(false);
          setIsEmailSubmitted(false);
          setRsvpMessage('');
          if (onRSVPChange) {
            onRSVPChange(false);
          }
          await refreshUser();
        }
      } else {
        showNotification(result.message, 'error');
      }
    } catch (error) {
      console.error('Error removing RSVP:', error);
      showNotification('Error removing RSVP: ' + error.message, 'error');
    }
  };

  const renderRSVPButton = () => (
    <button
      onClick={handleRSVPAction}
      className="w-full mt-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition-colors duration-200"
      disabled={isPastEvent || isRSVPd || (!user && !validateEmail(guestEmail))}
    >
      {isRSVPd 
        ? 'RSVP\'d' 
        : isEventCreator 
          ? 'RSVP to My Own Event' 
          : 'RSVP to Event'}
    </button>
  );

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setGuestEmail(email);
    if (email && !validateEmail(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const renderAttendee = (rsvp, index) => {
    const isCurrentUser = user && rsvp.email === user.email;
    const displayName = rsvp.name || 'Anonymous';
    
    return (
      <li key={rsvp.id} className={`flex items-center justify-between p-2 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
        <div className="flex items-center space-x-2">
          <UserAvatar user={rsvp} size="sm" />
          <span>
            {isCurrentUser ? "Me" : displayName}
            {isEventCreator && !isCurrentUser && ` (${rsvp.email})`}
            {isCurrentUser && (
              <span className="ml-2 bg-blue-700 text-white text-xs font-medium px-2.5 py-0.5 rounded">My RSVP</span>
            )}
          </span>
        </div>
      </li>
    );
  };

  return (
    <div className="mt-8 bg-white shadow-md rounded-lg p-4 sm:p-6" style={{ zIndex: 'var(--z-content1' }}>
      <h2 className="text-2xl font-bold mb-4">RSVP ({rsvpList.length})</h2>
      {message && (
        <div className="mb-4 p-3 bg-blue-100 text-blue-700 rounded-md">
          {message}
        </div>
      )}
      {isPastEvent ? (
        <p className="mb-4 text-gray-600">This event has already passed. RSVPs are no longer available.</p>
      ) : user ? (
        <div>
          {isRSVPd ? (
            <>
              <button
                onClick={() => handleRemoveRSVP(user.email)}
                className="w-full mt-2 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md transition-colors duration-200"
              >
                Remove RSVP
              </button>
            </>
          ) : (
            renderRSVPButton()
          )}
          {user.isGuest && showGuestMessage && (
            <div className="mt-2 p-3 bg-blue-100 text-blue-700 rounded-md relative">
              <p className="text-sm">
                Signed in as guest. <Link to="/profile" className="text-blue-500 hover:underline">Update your profile with your name and photo</Link> or stay anonymous (that's cool too).
              </p>
              <button 
                onClick={() => setShowGuestMessage(false)} 
                className="absolute top-1 right-1 text-blue-500 hover:text-blue-700"
              >
                ✕
              </button>
            </div>
          )}
        </div>
      ) : (
        <div>
          {!isEmailSubmitted ? (
            <>
              <input
                type="email"
                value={guestEmail}
                onChange={handleEmailChange}
                placeholder="Enter your email to RSVP"
                className={`w-full px-3 py-2 border ${emailError ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2`}
              />
              {emailError && <p className="text-red-500 text-sm mb-2">{emailError}</p>}
              {renderRSVPButton()}
            </>
          ) : (
            <>
              <p className="mb-4 text-gray-600">{rsvpMessage}</p>
              {isRSVPd && !rsvpMessage.includes("already RSVP'd") && (
                <button
                  onClick={() => handleRemoveRSVP(guestEmail)}
                  className="w-full mt-2 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md transition-colors duration-200"
                >
                  Remove RSVP
                </button>
              )}
            </>
          )}
        </div>
      )}
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-2">Attendees:</h3>
        <ul className="divide-y divide-gray-200 rounded-md overflow-hidden">
          {rsvpList.map((rsvp, index) => renderAttendee(rsvp, index))}
        </ul>
      </div>
    </div>
  );
};

export default RSVPManager;
