import React, { useState, useEffect } from 'react';
import { useAuth } from '../Auth';
import { db } from '../firebase';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { useNotification } from '../components/NotificationContext';
import UserAvatar from '../users/UserAvatar';

const CreateGroupModal = ({ isOpen, onClose, onGroupCreated }) => {
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [members, setMembers] = useState([]);
  const [smartUrl, setSmartUrl] = useState('');

  useEffect(() => {
    if (user) {
      setMembers([{
        uid: user.uid,
        email: user.email,
        name: user.displayName || user.name,
        photoURL: user.photoURL
      }]);
    }
  }, [user]);

  useEffect(() => {
    setSmartUrl(generateSmartUrl(groupName));
  }, [groupName]);

  function generateGroupCode() {
    return Math.random().toString(36).substring(2, 8).toUpperCase();
  }

  const handleGenerateCode = () => {
    setGroupCode(generateGroupCode());
  };

  const handleGroupCodeChange = (e) => {
    const newCode = e.target.value.replace(/\s/g, '').toUpperCase().slice(0, 10);
    setGroupCode(newCode);
  };

  const generateSmartUrl = (name) => {
    return name.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
  };

  const lookupUser = async (email) => {
    const userQuery = query(collection(db, 'users'), where('email', '==', email));
    const userSnapshot = await getDocs(userQuery);
    if (!userSnapshot.empty) {
      const userData = userSnapshot.docs[0].data();
      const userId = userSnapshot.docs[0].id;
      return {
        uid: userId,
        email: userData.email,
        name: userData.name || userData.displayName,
        photoURL: userData.photoURL
      };
    }
    return null;
  };

  const handleAddEmail = async () => {
    if (!newEmail.trim()) return;
    
    const existingMember = members.find(member => member.email === newEmail);
    if (existingMember) {
      showNotification('This email is already added to the group', 'info');
      return;
    }

    const userData = await lookupUser(newEmail);
    if (userData) {
      setMembers([...members, userData]);
    } else {
      setMembers([...members, { email: newEmail }]);
    }
    setNewEmail('');
  };

  const checkUrlUniqueness = async (url) => {
    const groupsRef = collection(db, 'groups');
    const q = query(groupsRef, where('url', '==', url));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty;
  };

  const generateUniqueUrl = async (baseName) => {
    let url = generateSmartUrl(baseName);
    let isUnique = await checkUrlUniqueness(url);
    let counter = 1;

    while (!isUnique) {
      url = `${generateSmartUrl(baseName)}-${counter}`;
      isUnique = await checkUrlUniqueness(url);
      counter++;
    }

    return url;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (groupName.length > 20) {
      showNotification('Group name must be 20 characters or less', 'error');
      return;
    }

    if (groupCode.length > 10) {
      showNotification('Group code must be 10 characters or less', 'error');
      return;
    }

    try {
      const uniqueUrl = await generateUniqueUrl(groupName);

      const newGroup = {
        name: groupName,
        description: groupDescription,
        createdBy: user.uid,
        members,
        events: [],
        groupCode: groupCode,
        createdAt: new Date(),
        url: uniqueUrl
      };

      const docRef = await addDoc(collection(db, 'groups'), newGroup);
      const createdGroup = { ...newGroup, id: docRef.id, isOwner: true };
      showNotification('Group created successfully', 'success');
      onGroupCreated(createdGroup);
      onClose();
    } catch (error) {
      console.error('Error creating group:', error);
      showNotification('Failed to create group. Please try again.', 'error');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" style={{ zIndex: 'var(--z-modal-overlay)' }}>
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white" style={{ zIndex: 'var(--z-modal)' }}>
        <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Create New Group</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="groupName" className="block text-sm font-medium text-gray-700">Group Name</label>
            <input
              id="groupName"
              type="text"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value.slice(0, 20))}
              placeholder="Enter group name"
              className="w-full p-2 mt-1 border rounded"
              required
              maxLength={20}
            />
            <p className="mt-1 text-xs text-gray-500">Max 20 characters</p>
          </div>
          <div className="mb-4">
  <label htmlFor="smartUrl" className="block text-sm font-medium text-gray-700">Smart URL</label>
  <input
    type="text"
    id="smartUrl"
    value={smartUrl}
    readOnly
    className="w-full p-2 mt-1 border rounded bg-gray-100"
  />
  <p className="mt-1 text-xs text-gray-500">This will be the URL for your group page</p>
</div>
          <div className="mb-4">
            <label htmlFor="groupDescription" className="block text-sm font-medium text-gray-700">Group Description</label>
            <textarea
              id="groupDescription"
              value={groupDescription}
              onChange={(e) => setGroupDescription(e.target.value)}
              placeholder="What is this group all about?"
              className="w-full p-2 mt-1 border rounded"
              rows={3}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="groupCode" className="block text-sm font-medium text-gray-700">Group Code</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                id="groupCode"
                value={groupCode}
                onChange={handleGroupCodeChange}
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="Create code or generate one"
                required
                maxLength={10}
              />
              <button
                type="button"
                onClick={handleGenerateCode}
                className="absolute inset-y-0 right-0 px-3 flex items-center bg-gray-100 hover:bg-gray-200 rounded-r-md"
              >
                <i className="fas fa-sync-alt"></i>
              </button>
            </div>
            <p className="mt-1 text-xs text-gray-500">
              This code allows others to join your group. Max 10 characters, no spaces. You will <strong>not</strong> be able to change it later.
            </p>
          </div>

          <div className="mb-4">
            <h4 className="font-medium mb-2">Members:</h4>
            <ul className="space-y-2">
              {members.map((member, index) => (
                <li key={index} className="flex items-center space-x-2">
                  <UserAvatar user={member} size="sm" />
                  <span>{member.name || member.email}</span>
                  {index === 0 ? (
                    <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs font-medium rounded-full">
                      Group Owner
                    </span>
                  ) : (
                    <>
                      <span>{member.email}</span>
                      {!member.uid && <span className="text-xs text-gray-500">(No account)</span>}
                      <button
                        type="button"
                        onClick={() => setMembers(members.filter((_, i) => i !== index))}
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex mb-4">
            <input
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="Add member email"
              className="w-full p-2 border rounded-l"
            />
            <button
              type="button"
              onClick={handleAddEmail}
              className="bg-green-500 text-white p-2 rounded-r"
            >
              Add
            </button>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 text-gray-800 p-2 rounded mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded"
            >
              Create Group
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateGroupModal;
