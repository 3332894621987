import React from 'react';
import { sendTestEmail } from '../emails/emailService';
import { useNotification } from '../components/NotificationContext';

export default function TestEmailButton() {
  const { showNotification } = useNotification();

  const handleSendTestEmail = async () => {
    try {
      const result = await sendTestEmail();
      if (result.success) {
        showNotification('Test email sent successfully', 'success');
      } else {
        showNotification('Failed to send test email: ' + result.error, 'error');
      }
    } catch (error) {
      console.error('Failed to send test email:', error);
      showNotification('Failed to send test email: ' + error.message, 'error');
    }
  };

  return (
    <button
      onClick={handleSendTestEmail}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    >
      Send Test Email
    </button>
  );
}