import React, { useState } from 'react';

const EmailShareModal = ({ isOpen, onClose, onSend, userEmail }) => {
  const [email, setEmail] = useState('');

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted'); // Add this log
    if (typeof onSend === 'function') {
      console.log('Calling onSend with:', email || userEmail); // Add this log
      onSend(email || userEmail);
    } else {
      console.error('onSend is not a function'); // Add this log
    }
  };

  return (
    <>
      <div 
        className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" 
        style={{ zIndex: 'var(--z-modal-overlay)' }}
        onClick={onClose}
      />
      <div 
        className="fixed inset-0 flex items-center justify-center"
        style={{ zIndex: 'var(--z-modal)' }}
      >
        <div className="relative bg-white rounded-lg p-8 m-4 max-w-xl w-full" onClick={e => e.stopPropagation()}>
          <div className="mt-3 text-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Sharing is Caring</h3>
            <form onSubmit={handleSubmit} className="mt-2 px-7 py-3">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={userEmail || "Enter email address"}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <div className="items-center px-4 py-3">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                  {email ? 'Send' : (userEmail ? 'Send to Myself' : 'Send')}
                </button>
                <button
                  type="button"
                  onClick={onClose}
                  className="mt-3 px-4 py-2 bg-gray-100 text-gray-700 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailShareModal;
