import React, { useState } from 'react';
import { updateUserInfoAcrossCollections } from '../utils/userUpdateUtils';
import { useAuth } from '../Auth';

const EmailChangeModal = ({ isOpen, onClose, onConfirm, currentEmail }) => {
  const [newEmail, setNewEmail] = useState('');
  const { user } = useAuth();

  if (!isOpen) return null;

  const handleConfirm = async () => {
    try {
      await onConfirm(newEmail);
      
      // Update user info across all collections
      await updateUserInfoAcrossCollections(user.uid, {
        email: newEmail,
      });

      onClose();
    } catch (error) {
      console.error('Error updating email:', error);
      // Handle error (show notification, etc.)
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" style={{ zIndex: 'var(--z-modal-overlay)' }}>
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white" style={{ zIndex: 'var(--z-modal)' }}>
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Change Email</h3>
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500">
              Current email: {currentEmail}
            </p>
            <input
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="New email"
              className="mt-2 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="items-center px-4 py-3">
            <button
              onClick={handleConfirm}
              className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              Confirm
            </button>
            <button
              onClick={onClose}
              className="mt-3 px-4 py-2 bg-gray-100 text-gray-700 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailChangeModal;
