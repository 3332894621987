import { db } from '../firebase';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, arrayUnion, arrayRemove, addDoc, writeBatch } from 'firebase/firestore';

export const getGroupByUrl = async (url) => {
  const groupsRef = collection(db, 'groups');
  const q = query(groupsRef, where('url', '==', url));
  const querySnapshot = await getDocs(q);
  
  if (!querySnapshot.empty) {
    const groupDoc = querySnapshot.docs[0];
    const groupData = groupDoc.data();
    
    // Fetch events
    const events = await Promise.all((groupData.eventIds || []).map(async (eventId) => {
      const eventDoc = await getDoc(doc(db, 'events', eventId));
      if (eventDoc.exists()) {
        return { id: eventDoc.id, ...eventDoc.data() };
      }
      return null;
    }));

    return { 
      id: groupDoc.id, 
      ...groupData, 
      events: events.filter(event => event !== null)
    };
  }
  return null;
};

export const getGroupById = async (groupId) => {
  const groupDoc = await getDoc(doc(db, 'groups', groupId));
  if (groupDoc.exists()) {
    const groupData = groupDoc.data();
    
    // Fetch events
    const events = await Promise.all((groupData.eventIds || []).map(async (eventId) => {
      const eventDoc = await getDoc(doc(db, 'events', eventId));
      if (eventDoc.exists()) {
        return { id: eventDoc.id, ...eventDoc.data() };
      }
      return null;
    }));

    return { 
      id: groupDoc.id, 
      ...groupData, 
      events: events.filter(event => event !== null)
    };
  }
  return null;
};

export const checkGroupMembership = async (userIdOrEmail, groupUrl) => {
  const group = await getGroupByUrl(groupUrl);
  if (!group) return false;
  return group.members.some(member => member.uid === userIdOrEmail || member.email === userIdOrEmail);
};

export const joinGroup = async (groupUrl, email, groupCode, user = null) => {
  const group = await getGroupByUrl(groupUrl);
  if (!group) throw new Error('Group not found');
  if (group.groupCode !== groupCode) throw new Error('Invalid group code');

  const groupRef = doc(db, 'groups', group.id);
  
  if (user) {
    // User is logged in, add full user details
    await updateDoc(groupRef, {
      members: arrayUnion({ 
        uid: user.uid, 
        email: user.email, 
        name: user.displayName || user.email, 
        photoURL: user.photoURL 
      })
    });
  } else {
    // User is not logged in, just verify if the email exists in members
    const existingMember = group.members.find(member => member.email === email);
    if (!existingMember) {
      throw new Error('Email not found in group members');
    }
    // If email exists, we don't need to update anything, just grant access
  }

  // Fetch and return the updated group
  return await getGroupByUrl(groupUrl);
};

export const addMemberToGroup = async (groupId, email) => {
  const groupRef = doc(db, 'groups', groupId);
  await updateDoc(groupRef, {
    members: arrayUnion({ email, uid: null, name: null, photoURL: null })
  });
};

export const removeMember = async (groupId, memberUid) => {
  const groupRef = doc(db, 'groups', groupId);
  const groupDoc = await getDoc(groupRef);
  
  if (groupDoc.exists()) {
    const groupData = groupDoc.data();
    const updatedMembers = groupData.members.filter(member => member.uid !== memberUid);
    
    await updateDoc(groupRef, {
      members: updatedMembers
    });
    
    console.log(`Member ${memberUid} removed from group ${groupId}`);
  } else {
    throw new Error('Group not found');
  }
};

export const getGroupsByUser = async (userId) => {
  try {
    const groupsRef = collection(db, 'groups');
    const q = query(groupsRef);
    const querySnapshot = await getDocs(q);

    const userGroups = querySnapshot.docs.map(doc => {
      const groupData = doc.data();
      const isMember = groupData.members.some(member => member.uid === userId);
      const isOwner = groupData.createdBy === userId;

      if (isOwner || isMember) {
        return {
          id: doc.id,
          ...groupData,
          isOwner: isOwner,
          url: groupData.url // Make sure this property exists in your group documents
        };
      }
      return null;
    }).filter(group => group !== null);

    return userGroups;
  } catch (error) {
    console.error('Error fetching user groups:', error);
    throw error;
  }
};

export const findUserByEmail = async (email) => {
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('email', '==', email));
  const querySnapshot = await getDocs(q);
  
  if (!querySnapshot.empty) {
    const userDoc = querySnapshot.docs[0];
    const userData = userDoc.data();
    return {
      uid: userDoc.id,
      email: userData.email,
      name: userData.displayName || userData.name,
      photoURL: userData.photoURL
    };
  }
  return null;
};

export const getUserEvents = async (userId) => {
  const eventsRef = collection(db, 'events');
  const q = query(eventsRef, where('createdBy', '==', userId));
  const querySnapshot = await getDocs(q);
  
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};

export const addEventToGroup = async (groupId, eventId) => {
  const groupRef = doc(db, 'groups', groupId);
  const eventRef = doc(db, 'events', eventId);

  await updateDoc(groupRef, {
    eventIds: arrayUnion(eventId)
  });

  await updateDoc(eventRef, {
    groupIds: arrayUnion(groupId)
  });
};

export const removeEventFromGroup = async (groupId, eventId) => {
  const groupRef = doc(db, 'groups', groupId);
  const eventRef = doc(db, 'events', eventId);

  await updateDoc(groupRef, {
    eventIds: arrayRemove(eventId)
  });

  await updateDoc(eventRef, {
    groupIds: arrayRemove(groupId)
  });
};

export const joinGroupByCode = async (groupCode, user) => {
  const groupsRef = collection(db, 'groups');
  const q = query(groupsRef, where('groupCode', '==', groupCode));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    throw new Error('Group not found');
  }

  const groupDoc = querySnapshot.docs[0];
  const groupData = groupDoc.data();

  if (groupData.members.some(member => member.uid === user.uid)) {
    throw new Error('You are already a member of this group');
  }

  const newMember = {
    uid: user.uid,
    email: user.email,
    name: user.displayName || user.email,
    photoURL: user.photoURL
  };

  await updateDoc(doc(db, 'groups', groupDoc.id), {
    members: arrayUnion(newMember)
  });

  return {
    id: groupDoc.id,
    ...groupData,
    members: [...groupData.members, newMember],
    isOwner: false
  };
};

export const createGroup = async (groupData, user) => {
  try {
    const newGroup = {
      ...groupData,
      createdBy: user.uid,
      members: [{ uid: user.uid, email: user.email, name: user.displayName, photoURL: user.photoURL }],
      createdAt: new Date(),
      eventIds: [],
    };

    const docRef = await addDoc(collection(db, 'groups'), newGroup);
    return { id: docRef.id, ...newGroup, isOwner: true };
  } catch (error) {
    console.error('Error creating group:', error);
    throw error;
  }
};

export const updateGroupMembershipsByEmail = async (email, userId) => {
  const groupsRef = collection(db, 'groups');
  const q = query(groupsRef, where('members', 'array-contains', { email: email }));
  const querySnapshot = await getDocs(q);

  const batch = writeBatch(db);

  querySnapshot.forEach((doc) => {
    const groupRef = doc.ref;
    const members = doc.data().members;
    const updatedMembers = members.map(member => 
      member.email === email ? { ...member, uid: userId } : member
    );
    batch.update(groupRef, { members: updatedMembers });
  });

  await batch.commit();
};
