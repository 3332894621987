import { db } from '../firebase';
import { collection, query, where, getDocs, addDoc, doc, updateDoc, increment, getDoc } from 'firebase/firestore';
import { getUserProfileByUid } from '../users/userManagement';

export const getEventByUrl = async (url) => {
  try {
    const eventsRef = collection(db, 'events');
    const q = query(eventsRef, where('url', '==', url));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const eventDoc = querySnapshot.docs[0];
      const eventData = eventDoc.data();
      
      return {
        id: eventDoc.id,
        ...eventData,
        // We don't need to fetch hostName and hostEmail here if they're already in the database
      };
    }
    return null;
  } catch (error) {
    console.error('Error fetching event:', error);
    throw error;
  }
};

export const handleRSVPAfterSignIn = async (email, eventUrl, setNotification) => {
  try {
    const eventDoc = await getEventByUrl(eventUrl);
    if (eventDoc) {
      const rsvpsRef = collection(db, 'rsvps');
      const q = query(rsvpsRef, where('eventId', '==', eventDoc.id), where('email', '==', email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        // User wasn't RSVPed before, so let's RSVP them now
        await addDoc(rsvpsRef, {
          email,
          eventId: eventDoc.id,
          date: new Date().toISOString(),
        });
        
        // Update the RSVP count in the event document
        const eventRef = doc(db, 'events', eventDoc.id);
        await updateDoc(eventRef, {
          rsvpCount: increment(1)
        });

        setNotification({ message: 'You have successfully RSVP\'d to the event!', type: 'success' });
      } else {
        setNotification({ message: 'You were already RSVP\'d to this event.', type: 'info' });
      }
    }
  } catch (error) {
    console.error('Error handling RSVP after sign-in:', error);
    setNotification({ message: 'Error processing RSVP: ' + error.message, type: 'error' });
  }
};

export const addRSVP = async (email, eventId) => {
  const rsvpRef = collection(db, 'rsvps');
  await addDoc(rsvpRef, { 
    email, 
    eventId, 
    date: new Date().toISOString() 
  });

  // Update the RSVP count in the event document
  const eventRef = doc(db, 'events', eventId);
  await updateDoc(eventRef, {
    rsvpCount: increment(1)
  });
};

export const createEvent = async (eventData, userId) => {
  try {
    const userProfile = await getUserProfileByUid(userId);
    const newEventRef = await addDoc(collection(db, 'events'), {
      ...eventData,
      isPrivate: eventData.isPrivate || false, // Add this line
      createdBy: userId,
      hostName: userProfile?.name || 'Anonymous',
      hostEmail: userProfile?.email || 'No email provided',
      createdAt: new Date()
    });
    return newEventRef.id;
  } catch (error) {
    console.error('Error creating event:', error);
    throw error;
  }
};

export const updateEvent = async (eventId, eventData) => {
  try {
    const eventRef = doc(db, 'events', eventId);
    await updateDoc(eventRef, {
      ...eventData,
      isPrivate: eventData.isPrivate || false, // Add this line
    });
    return true;
  } catch (error) {
    console.error('Error updating event:', error);
    throw error;
  }
};
