import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../Auth';
import LogoutButton from './LogoutButton';

const Footer = () => {
  const { isAuthenticated } = useAuth();

  return (
    <footer className="bg-blue-700 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-6 md:mb-0">
            <div className="text-sm max-w-md text-center md:text-left">
              <span className="relative inline-block">
                <span className="font-bold text-white px-2 py-1 bg-blue-500 rounded animate-pulse">Gatherly.</span>
              </span> The simple way to <span className="relative inline-block">
                <span className="text-white px-1 bg-blue-500 rounded animate-pulse">org</span>
              </span>anize gatherings.
              <br />
              No account needed - just RSVP and add to your calendar!
            </div>
          </div>
          <nav className="flex flex-wrap justify-center md:justify-end gap-4">
            <Link to="/" className="hover:text-blue-200 transition duration-300">Home</Link>
            {isAuthenticated ? (
              <>
                <Link to="/profile" className="hover:text-blue-200 transition duration-300">My Profile</Link>
                <Link to="/my-events" className="hover:text-blue-200 transition duration-300">My Events</Link>
                <Link to="/my-groups" className="hover:text-blue-200 transition duration-300">My Groups</Link>
                <LogoutButton className="hover:text-blue-200 transition duration-300" />
              </>
            ) : (
              <Link to="/login" className="hover:text-blue-200 transition duration-300">Sign-up or Sign-in</Link>
            )}
          </nav>
        </div>
        <div className="mt-8 pt-4 border-t border-blue-600 text-sm text-center">
          <p>2024 Gatherly.org</p>
          <p>Born from the frustration of overly complicated event sites.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;