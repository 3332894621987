import React from 'react';
import { Link } from 'react-router-dom';

const Whoops = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col py-6 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-2xl">
        <h2 className="text-center text-2xl font-extrabold text-gray-900">
          Gatherly
        </h2>
        <div className="mt-4 relative">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center">
            <span className="px-3 bg-gray-100 text-sm text-gray-500">
              The simple way to organize gatherings
            </span>
          </div>
        </div>
      </div>

      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-2xl">
        <div className="bg-white shadow-lg overflow-hidden rounded-lg">
          <div className="p-6 bg-gradient-to-br from-blue-500 to-blue-600 text-white">
            <h2 className="text-3xl font-bold mb-2">Whoops! Party Mix-up</h2>
            <p className="mb-4">Looks like you've stumbled into the wrong gathering! No worries, it happens to the best of us.</p>
            <p className="mb-6">There are plenty of exciting public events waiting for you to join. Why not check those out?</p>
            <Link
              to="/"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-blue-600 bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
            >
              Discover Public Events
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whoops;