import { db } from '../firebase';
import { collection, query, where, getDoc, getDocs, addDoc, deleteDoc, doc, updateDoc, increment, runTransaction } from 'firebase/firestore';
import { getUserProfileByEmail, createGuestUserDocument, createOrUpdateUserDocument, sendWelcomeEmailIfNeeded } from '../users/userManagement';
import { createSession } from '../users/sessionManagement';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { sendEventDetailEmail, sendWelcomeEmail } from '../emails/emailService';

// Add RSVP
export const addRSVP = async (eventId, userId, email) => {
  const eventRef = doc(db, 'events', eventId);

  try {
    await runTransaction(db, async (transaction) => {
      const eventDoc = await transaction.get(eventRef);
      if (!eventDoc.exists()) {
        throw new Error("Event does not exist!");
      }

      const newRsvpCount = (eventDoc.data().rsvpCount || 0) + 1;
      
      const newRsvpRef = await addDoc(collection(db, 'rsvps'), {
        eventId,
        userId,
        email,
        createdAt: new Date()
      });

      transaction.update(eventRef, { rsvpCount: newRsvpCount });
    });

    return { success: true, message: 'RSVP added successfully' };
  } catch (error) {
    console.error("Error adding RSVP: ", error);
    return { success: false, error: error.message };
  }
};

// Remove RSVP
export const removeRSVP = async (eventId, userEmail) => {
  const eventRef = doc(db, 'events', eventId);

  try {
    await runTransaction(db, async (transaction) => {
      const eventDoc = await transaction.get(eventRef);
      if (!eventDoc.exists()) {
        throw new Error("Event does not exist!");
      }

      const newRsvpCount = Math.max((eventDoc.data().rsvpCount || 0) - 1, 0);

      const rsvpQuery = query(
        collection(db, 'rsvps'),
        where('eventId', '==', eventId),
        where('email', '==', userEmail)
      );
      const rsvpSnapshot = await getDocs(rsvpQuery);

      if (!rsvpSnapshot.empty) {
        transaction.delete(rsvpSnapshot.docs[0].ref);
      }

      transaction.update(eventRef, { rsvpCount: newRsvpCount });
    });

    return { success: true, message: 'RSVP removed successfully' };
  } catch (error) {
    console.error("Error removing RSVP: ", error);
    return { success: false, error: error.message };
  }
};

// Check if user has RSVP'd
export const checkRSVP = async (eventId, userEmail) => {
  const rsvpQuery = query(
    collection(db, 'rsvps'),
    where('eventId', '==', eventId),
    where('email', '==', userEmail)
  );
  const rsvpSnapshot = await getDocs(rsvpQuery);
  return !rsvpSnapshot.empty;
};

// Get all RSVPs for an event
export const getRSVPs = async (eventId) => {
  console.log('Fetching RSVPs for event:', eventId);
  const rsvpsRef = collection(db, 'rsvps');
  const q = query(rsvpsRef, where('eventId', '==', eventId));
  try {
    console.log('Executing query...');
    const querySnapshot = await getDocs(q);
    console.log(`Found ${querySnapshot.size} RSVPs for event ${eventId}`);
    const rsvps = await Promise.all(querySnapshot.docs.map(async doc => {
      const rsvpData = doc.data();
      console.log(`RSVP data for ${rsvpData.email}:`, rsvpData);
      const userProfile = await getUserProfileByEmail(rsvpData.email);
      console.log(`User profile for ${rsvpData.email}:`, userProfile);
      return {
        id: doc.id,
        ...rsvpData,
        name: userProfile?.name || 'Anonymous',
        photoURL: userProfile?.photoURL || null,
        user: userProfile,
        isGuest: userProfile?.isGuest || false
      };
    }));

    // Remove duplicates
    const uniqueRSVPs = await removeDuplicateRSVPs(eventId, rsvps);

    console.log(`Processed RSVPs:`, uniqueRSVPs);
    return uniqueRSVPs;
  } catch (error) {
    console.error(`Error fetching RSVPs for event ${eventId}:`, error);
    throw error;
  }
};

// Utility function to remove duplicate RSVPs
const removeDuplicateRSVPs = async (eventId, rsvps) => {
  const uniqueRSVPs = rsvps.reduce((acc, current) => {
    const x = acc.find(item => item.email === current.email);
    if (!x) {
      return acc.concat([current]);
    } else {
      // If duplicate found, keep the one with the earlier createdAt
      if (new Date(current.createdAt) < new Date(x.createdAt)) {
        return acc.map(item => item.email === current.email ? current : item);
      } else {
        return acc;
      }
    }
  }, []);

  // Delete duplicate RSVPs from Firestore
  const duplicates = rsvps.filter(rsvp => 
    !uniqueRSVPs.some(uniqueRsvp => uniqueRsvp.id === rsvp.id)
  );

  for (const duplicate of duplicates) {
    await deleteDoc(doc(db, 'rsvps', duplicate.id));
  }

  // Update event's rsvpCount
  const eventRef = doc(db, 'events', eventId);
  await updateDoc(eventRef, { rsvpCount: uniqueRSVPs.length });

  return uniqueRSVPs;
};

// Handle RSVP for both logged-in and non-logged-in users
export const handleRSVP = async (email, eventId, isSignedIn) => {
  const userDoc = await getUserProfileByEmail(email);
  const eventDoc = await getDoc(doc(db, 'events', eventId));
  const eventData = eventDoc.data();
  
  if (userDoc) {
    // Existing user
    const isAlreadyRSVPd = await checkRSVP(eventId, email);
    
    // Send sign-in link only if the user is not signed in
    if (!isSignedIn) {
      try {
        const actionCodeSettings = {
          url: window.location.href,
          handleCodeInApp: true,
        };
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        localStorage.setItem('emailForSignIn', email);
      } catch (error) {
        console.error("Error sending sign-in link:", error);
      }
    }
    
    if (isAlreadyRSVPd) {
      return { 
        success: true, 
        message: 'You\'ve already RSVP\'d for this event. Check your email for details.', 
        isExistingUser: true,
        isRSVPd: true,
        wasAlreadyRSVPd: true
      };
    } else {
      await addRSVP(eventId, userDoc.uid, email);
      // Send RSVP confirmation email
      await sendEventDetailEmail(email, userDoc.name || 'Friend', {
        ...eventData,
        id: eventId,
        url: eventData.url
      }, true);
      return { 
        success: true, 
        message: 'You\'re now RSVP\'d! Check your email for details.', 
        isExistingUser: true,
        isRSVPd: true,
        wasAlreadyRSVPd: false
      };
    }
  } else {
    // New user
    const newUser = await createGuestUserDocument(email);
    createSession(email, true);
    await addRSVP(eventId, newUser.uid, email);
        
    await sendEventDetailEmail(email, 'Friend', {
      ...eventData,
      id: eventId,
      url: eventData.url
    }, true);
    
    return { 
      success: true, 
      message: 'Guest account created and RSVP confirmed! You\'re now logged in. Check your email for details.', 
      user: newUser, 
      isNewUser: true,
      isRSVPd: true,
      wasAlreadyRSVPd: false
    };
  }
};

// Toggle requiresRSVP flag for an event and delete RSVPs if necessary
export const toggleRequiresRSVP = async (eventId) => {
  const eventRef = doc(db, 'events', eventId);
  
  try {
    const result = await runTransaction(db, async (transaction) => {
      const eventDoc = await transaction.get(eventRef);
      if (!eventDoc.exists()) {
        throw new Error("Event does not exist!");
      }
      
      const currentRequiresRSVP = eventDoc.data().requiresRSVP || false;
      
      if (currentRequiresRSVP) {
        // If we're turning off RSVP, delete all RSVPs for this event
        const rsvpsRef = collection(db, 'rsvps');
        const rsvpQuery = query(rsvpsRef, where('eventId', '==', eventId));
        const rsvpSnapshot = await getDocs(rsvpQuery);
        
        rsvpSnapshot.forEach((doc) => {
          transaction.delete(doc.ref);
        });
        
        // Update the event document
        transaction.update(eventRef, { 
          requiresRSVP: false,
          rsvpCount: 0
        });
        
        return { success: true, message: 'RSVP requirement removed and all RSVPs deleted' };
      } else {
        // If we're turning on RSVP, just update the flag
        transaction.update(eventRef, { requiresRSVP: true });
        return { success: true, message: 'RSVP requirement added' };
      }
    });
    
    return result;
  } catch (error) {
    console.error("Error updating RSVP requirement: ", error);
    return { success: false, error: error.message || 'An unknown error occurred' };
  }
};
