// eventActions.js
import { formatDateForICS, getIANATimeZone, escapeText, wrapLine } from '../utils/dateUtils';
import { DateTime } from 'luxon';
import ICAL from 'ical.js';
import { sendEventDetailEmail } from '../emails/emailService';

export const handleCopyLink = (url, showNotification) => {
  navigator.clipboard.writeText(url)
    .then(() => showNotification('Event link copied to clipboard!', 'success'))
    .catch(() => showNotification('Failed to copy link.', 'error'));
};

export const handleShareEmail = async (event, url, recipientEmail, recipientName = 'Gatherly User') => {
  try {
    const result = await sendEventDetailEmail(recipientEmail, recipientName, {
      ...event,
      url: url.split('/').pop() // Extract the event URL from the full URL
    });

    if (result.success) {
      return { success: true, message: 'Event details sent successfully' };
    } else {
      throw new Error(result.error || 'Failed to send email');
    }
  } catch (error) {
    console.error('Error sending event detail email:', error);
    return { success: false, error: error.message };
  }
};

export const generateICSLink = (event) => {
  if (!event) return null;
  
  const ianaTimeZone = event.timezone || DateTime.local().zoneName;
  
  const start = DateTime.fromFormat(`${event.startDate} ${event.startTime}`, 'yyyy-MM-dd HH:mm', { zone: ianaTimeZone });
  const end = DateTime.fromFormat(`${event.endDate} ${event.endTime}`, 'yyyy-MM-dd HH:mm', { zone: ianaTimeZone });
  
  if (!start.isValid || !end.isValid) {
    console.error('Invalid start or end date/time');
    return null;
  }
  
  const eventUrl = `${window.location.origin}/event/${event.url}`;
  
  const stripHtml = (html) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };
  
  const description = event.description ? stripHtml(event.description) : '';
  
  const comp = new ICAL.Component(['vcalendar', [], []]);
  comp.updatePropertyWithValue('prodid', '-//Gatherly//Events//EN');
  comp.updatePropertyWithValue('version', '2.0');
  comp.updatePropertyWithValue('calscale', 'GREGORIAN');
  comp.updatePropertyWithValue('method', 'PUBLISH');

  // Add VTIMEZONE component
  const tzComp = new ICAL.Component('vtimezone');
  tzComp.addPropertyWithValue('tzid', ianaTimeZone);
  tzComp.addPropertyWithValue('x-lic-location', ianaTimeZone);

  const tzInfo = ICAL.TimezoneService.get(ianaTimeZone);
  if (tzInfo) {
    const currentYear = new Date().getFullYear();
    const transitions = tzInfo.vtimezone.getAllSubcomponents().filter(
      comp => comp.name === 'daylight' || comp.name === 'standard'
    );

    transitions.forEach(transition => {
      const subComp = new ICAL.Component(transition.name);
      const dtstart = transition.getFirstPropertyValue('dtstart');
      dtstart.year = currentYear;
      
      subComp.addPropertyWithValue('tzoffsetfrom', transition.getFirstPropertyValue('tzoffsetfrom'));
      subComp.addPropertyWithValue('tzoffsetto', transition.getFirstPropertyValue('tzoffsetto'));
      subComp.addPropertyWithValue('tzname', transition.getFirstPropertyValue('tzname'));
      subComp.addPropertyWithValue('dtstart', dtstart);
      
      if (transition.hasProperty('rrule')) {
        subComp.addPropertyWithValue('rrule', transition.getFirstPropertyValue('rrule'));
      }
      
      tzComp.addSubcomponent(subComp);
    });
  }

  comp.addSubcomponent(tzComp);

  const vevent = new ICAL.Component('vevent');
  const eventProp = new ICAL.Event(vevent);

  eventProp.summary = event.name;
  eventProp.description = `Details: ${eventUrl}\n\n${description}`;
  eventProp.location = event.location;
  eventProp.startDate = ICAL.Time.fromJSDate(start.toJSDate(), false);
  eventProp.endDate = ICAL.Time.fromJSDate(end.toJSDate(), false);
  
  // Set the timezone
  const timezone = new ICAL.Timezone({
    tzid: ianaTimeZone,
    component: tzComp
  });
  eventProp.startDate.zone = timezone;
  eventProp.endDate.zone = timezone;

  // Add UID, URL, and other properties
  vevent.addPropertyWithValue('uid', `${event.id}-${Date.now()}@gatherly.org`);
  vevent.addPropertyWithValue('url', eventUrl);
  vevent.addPropertyWithValue('status', 'CONFIRMED');
  vevent.addPropertyWithValue('sequence', '0');
  vevent.addPropertyWithValue('created', ICAL.Time.now());
  vevent.addPropertyWithValue('last-modified', ICAL.Time.now());
  vevent.addPropertyWithValue('dtstamp', ICAL.Time.now());

  // Add organizer if available
  if (event.hostName && event.hostEmail) {
    vevent.addPropertyWithValue('organizer', `mailto:${event.hostEmail}`, { cn: event.hostName });
  }

  comp.addSubcomponent(vevent);
  
  const icsContent = comp.toString();
  
  const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
  const url = URL.createObjectURL(blob);
  return url;
};
