import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EventCard from '../events/EventCard';
import UserAvatar from '../users/UserAvatar';
import ConfirmationModal from '../components/ConfirmationModal';
import { useNotification } from '../components/NotificationContext';
import { useAuth } from '../Auth';
import { db } from '../firebase';
import { doc, updateDoc, arrayUnion, arrayRemove, deleteDoc } from 'firebase/firestore';
import GroupFormManager from './GroupFormManager';
import { findUserByEmail, getUserEvents, addEventToGroup, removeEventFromGroup, getGroupById, removeMember } from './groupServices';
import { updateGroupEventRelationship } from '../utils/groupEventRelationship';
import { collection, query, where, getDocs } from 'firebase/firestore';

const GroupManager = ({ group: initialGroup, onGroupUpdated }) => {
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const [group, setGroup] = useState(initialGroup);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isRemoveMemberModalOpen, setIsRemoveMemberModalOpen] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState(null);
  const [newMemberEmail, setNewMemberEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const menuRef = useRef(null);

  const [userEvents, setUserEvents] = useState([]);
  const [isManageEventsModalOpen, setIsManageEventsModalOpen] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState(initialGroup.eventIds || []);

  const isOwner = user.uid === group.createdBy;
  const isMember = !isOwner && group.members.some(member => member.uid === user.uid);

  useEffect(() => {
    if (isOwner) {
      fetchUserEvents();
    }
    fetchGroupEvents();
  }, [isOwner, user.uid, group.id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchUserEvents = async () => {
    const events = await getUserEvents(user.uid);
    setUserEvents(events);
  };

  const fetchGroupEvents = async () => {
    try {
      const updatedGroup = await getGroupById(group.id);
      if (updatedGroup && updatedGroup.events) {
        setGroup(prevGroup => ({ ...prevGroup, events: updatedGroup.events }));
      }
    } catch (error) {
      console.error('Error fetching group events:', error);
      showNotification('Error fetching group events', 'error');
    }
  };

  const handleExitGroup = async () => {
    try {
      await removeMember(group.id, user.uid);
      showNotification('Successfully left the group', 'success');
      onGroupUpdated(null);
      navigate('/my-groups');
    } catch (error) {
      console.error('Error exiting group:', error);
      showNotification('Failed to exit group. Please try again.', 'error');
    }
  };

  const handleDeleteGroup = async () => {
    try {
      await deleteDoc(doc(db, 'groups', group.id));
      showNotification('Group deleted successfully', 'success');
      onGroupUpdated(null);
      navigate('/my-groups');
    } catch (error) {
      console.error('Error deleting group:', error);
      showNotification('Error deleting group', 'error');
    }
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleAddMember = async () => {
    if (!newMemberEmail.trim()) {
      setEmailError('Email cannot be empty');
      return;
    }

    if (!validateEmail(newMemberEmail)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    setEmailError('');

    try {
      const groupRef = doc(db, 'groups', group.id);
      const existingUser = await findUserByEmail(newMemberEmail);
      
      let newMember;
      if (existingUser) {
        newMember = existingUser;
      } else {
        newMember = { email: newMemberEmail, uid: null, name: null, photoURL: null };
      }

      await updateDoc(groupRef, {
        members: arrayUnion(newMember)
      });

      const updatedGroup = await getGroupById(group.id);
      setGroup(updatedGroup);
      onGroupUpdated(updatedGroup);
      setNewMemberEmail('');
      showNotification('Member added successfully', 'success');
    } catch (error) {
      console.error('Error adding member:', error);
      showNotification('Error adding member', 'error');
    }
  };

  const handleRemoveMember = async () => {
    if (!memberToRemove) return;

    try {
      const groupRef = doc(db, 'groups', group.id);
      await updateDoc(groupRef, {
        members: arrayRemove(memberToRemove)
      });
      const updatedGroup = await getGroupById(group.id);
      setGroup(updatedGroup);
      onGroupUpdated(updatedGroup);
      showNotification('Member removed successfully', 'success');
    } catch (error) {
      console.error('Error removing member:', error);
      showNotification('Error removing member', 'error');
    } finally {
      setMemberToRemove(null);
      setIsRemoveMemberModalOpen(false);
    }
  };

  const handleGroupUpdated = (updatedGroup) => {
    setGroup(updatedGroup);
    setIsEditModalOpen(false);
    showNotification('Group updated successfully', 'success');
    onGroupUpdated(updatedGroup);
  };

  const handleManageEvents = async () => {
    const addEvents = selectedEvents.filter(eventId => !group.eventIds?.includes(eventId));
    const removeEvents = group.eventIds?.filter(eventId => !selectedEvents.includes(eventId)) || [];

    try {
      for (const eventId of addEvents) {
        await addEventToGroup(group.id, eventId);
      }
      for (const eventId of removeEvents) {
        await removeEventFromGroup(group.id, eventId);
      }
      const updatedGroup = await getGroupById(group.id);
      setGroup(updatedGroup);
      onGroupUpdated(updatedGroup);
      showNotification('Events updated successfully', 'success');
      setIsManageEventsModalOpen(false);
    } catch (error) { 
      console.error('Error managing events:', error);
      showNotification('Error updating events', 'error');
    }
  };

  const toggleEventSelection = (eventId) => {
    setSelectedEvents(prev => 
      prev.includes(eventId) 
        ? prev.filter(id => id !== eventId)
        : [...prev, eventId]
    );
  };

  const sortedMembers = React.useMemo(() => {
    return group.members.sort((a, b) => {
      if (a.uid === user.uid) return -1;
      if (b.uid === user.uid) return 1;
      if (a.uid === group.createdBy) return -1;
      if (b.uid === group.createdBy) return 1;
      return 0;
    });
  }, [group.members, user.uid, group.createdBy]);

  const handleEventSaved = (updatedEvent) => {
    setGroup(prevGroup => ({
      ...prevGroup,
      events: prevGroup.events.map(event => 
        event.id === updatedEvent.id ? updatedEvent : event
      )
    }));
    showNotification('Event updated successfully', 'success');
  };

  const handleAddEvent = async (eventId) => {
    try {
      await updateGroupEventRelationship(eventId, group.id, true);
      // Update local state if necessary
    } catch (error) {
      console.error('Error adding event to group:', error);
    }
  };

  const handleRemoveEvent = async (eventId) => {
    try {
      await updateGroupEventRelationship(eventId, group.id, false);
      // Update local state if necessary
    } catch (error) {
      console.error('Error removing event from group:', error);
    }
  };

  return (
    <div className="overflow-hidden mb-6">
      <div className="bg-gradient-to-r from-blue-500 to-blue-600 p-4 relative w-screen">
        <div className="max-w-6xl mx-auto">
          <div className="flex justify-between items-start">
            <div className="flex-grow">
              <h3 className="text-2xl font-bold text-white mb-2">
                <Link to={`/group/${group.url}`} className="hover:text-blue-100 transition duration-300">
                  Group: {group.name}
                </Link>
              </h3>
              <p className="text-blue-100 mb-2">{group.description}</p>
            </div>
            {isOwner && (
              <div className="relative" ref={menuRef}>
                <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="text-white hover:text-blue-200 transition duration-300 focus:outline-none"
                >
                  <i className="fas fa-ellipsis-v text-xl"></i>
                </button>
                {isMenuOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                    <button
                      onClick={() => setIsEditModalOpen(true)}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition duration-300"
                    >
                      Edit Group
                    </button>
                    <button
                      onClick={() => setIsDeleteModalOpen(true)}
                      className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-100 transition duration-300"
                    >
                      Delete Group
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="flex items-center space-x-1 mt-2">
            {isOwner && (
              <span className="bg-blue-700 text-white text-xs font-semibold px-2 py-1 rounded-full">
                My Group
              </span>
            )}
            <div className="bg-blue-700 text-white text-xs font-medium px-3 py-1 rounded-full flex items-center">
              <span className="mr-2">Code: {group.groupCode}</span>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(group.groupCode);
                  showNotification('Group code copied to clipboard', 'success');
                }}
                className="text-white hover:text-blue-200 transition duration-300 focus:outline-none"
                title="Copy group code"
              >
                <i className="fas fa-copy"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-6xl mx-auto p-6">
          <div className="lg:flex lg:space-x-8">
            <div className="lg:w-2/3 mb-8 lg:mb-0">
              <div className="flex justify-between items-center mb-4">
                <h4 className="text-xl font-semibold text-gray-800">Group Events</h4>
                {isOwner && (
                  <button
                    onClick={() => setIsManageEventsModalOpen(true)}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  >
                    Manage Events
                  </button>
                )}
              </div>
              {group.events && group.events.length > 0 ? (
                <div className="space-y-4">
                  {group.events.map(event => (
                    <EventCard 
                      key={event.id}
                      event={{
                        ...event,
                        isMyEvent: event.createdBy === user.uid,
                        isPast: new Date(event.endDate) < new Date(),
                        groupNames: [group.name],
                        groupUrls: [group.url]
                      }}
                      mode="list"
                      onEdit={handleEventSaved}
                      disableFiltering={true}
                    />
                  ))}
                </div>
              ) : (
                <p className="text-gray-500 italic">No events in this group yet.</p>
              )}
            </div>
            
            <div className="lg:w-1/3">
              <h4 className="text-xl font-semibold text-gray-800 mb-4">Group Members</h4>
              <ul className="space-y-3">
                {sortedMembers.map(member => (
                  <li key={member.uid || member.email} className="flex items-center justify-between border-b border-gray-200 pb-2 last:border-b-0">
                    <div className="flex items-center">
                      <UserAvatar user={member} size="sm" />
                      <span className="ml-3">
                        {member.uid === user.uid ? (
                          <>
                            <span className="font-medium">You</span>
                            {!isOwner && (
                              <button
                                onClick={() => setIsExitModalOpen(true)}
                                className="ml-2 bg-red-500 hover:bg-red-600 text-white px-2 py-1 rounded-md text-xs font-medium transition duration-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                              >
                                Exit Group
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            <span className="font-medium">{member.name || member.email}</span>
                            {member.uid === group.createdBy && (
                              <>
                                <span className="ml-2 text-gray-500 text-sm">
                                  ({member.email})
                                </span>
                                <span className="ml-2 bg-blue-100 text-blue-800 text-xs font-medium px-2 py-1 rounded-full">
                                  Group Owner
                                </span>
                              </>
                            )}
                            {!member.uid && (
                              <>
                                <span className="ml-2 bg-gray-100 text-gray-800 text-xs font-medium px-2 py-1 rounded-full">
                                  No Account
                                </span>
                              </>
                            )}
                          </>
                        )}
                      </span>
                    </div>
                    {isOwner && member.uid !== group.createdBy && member.uid !== user.uid && (
                      <button
                        onClick={() => {
                          setMemberToRemove(member);
                          setIsRemoveMemberModalOpen(true);
                        }}
                        className="text-red-600 hover:text-red-800 transition duration-300 focus:outline-none"
                        aria-label="Remove member"
                      >
                        <i className="fas fa-user-minus"></i>
                      </button>
                    )}
                  </li>
                ))}
              </ul>
              {isOwner && (
                <div className="mt-4">
                  <div className="flex">
                    <input
                      type="email"
                      value={newMemberEmail}
                      onChange={(e) => {
                        setNewMemberEmail(e.target.value);
                        setEmailError('');
                      }}
                      placeholder="Add member email"
                      className={`flex-grow p-2 border rounded-l focus:outline-none focus:ring-1 focus:ring-green-600 ${
                        emailError ? 'border-red-500' : ''
                      }`}
                    />
                    <button
                      onClick={handleAddMember}
                      className="bg-green-600 text-white p-2 rounded-r hover:bg-green-500 transition duration-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                    >
                      Add
                    </button>
                  </div>
                  {emailError && (
                    <p className="text-red-500 text-sm mt-1">{emailError}</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={isExitModalOpen}
        onClose={() => setIsExitModalOpen(false)}
        onConfirm={handleExitGroup}
        message="Are you sure you want to exit this group?"
      />
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteGroup}
        message="Are you sure you want to delete this group? This action cannot be undone."
      />
      <ConfirmationModal
        isOpen={isRemoveMemberModalOpen}
        onClose={() => {
          setIsRemoveMemberModalOpen(false);
          setMemberToRemove(null);
        }}
        onConfirm={handleRemoveMember}
        message={`Are you sure you want to remove ${memberToRemove?.name || memberToRemove?.email} from the group?`}
      />
      {isEditModalOpen && (
        <GroupFormManager
          group={group}
          onClose={() => setIsEditModalOpen(false)}
          onGroupUpdated={handleGroupUpdated}
        />
      )}
      {isManageEventsModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg w-96 max-h-[80vh] overflow-y-auto">
            <h3 className="text-xl font-bold mb-4">Manage Events</h3>
            <div className="mb-4">
              <h4 className="font-medium mb-2">Select events to include in the group:</h4>
              {userEvents.map(event => (
                <div key={event.id} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id={`event-${event.id}`}
                    checked={selectedEvents.includes(event.id)}
                    onChange={() => toggleEventSelection(event.id)}
                    className="mr-2"
                  />
                  <label htmlFor={`event-${event.id}`}>{event.name}</label>
                </div>
              ))}
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setIsManageEventsModalOpen(false)}
                className="mr-2 bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleManageEvents}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-500"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupManager;
