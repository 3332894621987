import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../Auth';
import UserAvatar from '../users/UserAvatar';
import LogoutButton from './LogoutButton';
import AddEventModal from '../events/AddEventModal';

const Header = () => {
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isEventListPage = location.pathname === '/' || location.pathname === '/events';

  const renderUserMenu = () => (
    <div className="relative" ref={menuRef}>
      <button onClick={toggleMenu} className="flex items-center focus:outline-none">
        <UserAvatar user={user} size="sm" className="cursor-pointer" />
        <span className="ml-2 hidden md:inline">{user.displayName || user.name || user.email}</span>
      </button>
      {isMenuOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
          <Link to="/profile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => setIsMenuOpen(false)}>My Profile</Link>
          <Link to="/my-events" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => setIsMenuOpen(false)}>My Events</Link>
          <Link to="/my-groups" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => setIsMenuOpen(false)}>My Groups</Link>
          <LogoutButton className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" />
        </div>
      )}
    </div>
  );

  return (
    <header className="bg-blue-700 text-white fixed top-0 left-0 right-0 shadow-md" style={{ zIndex: 'var(--z-header)' }}>
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          <Link to="/" className="text-2xl font-bold">Gatherly</Link>
          {process.env.NODE_ENV === 'development' && (
            <div className="text-sm bg-yellow-500 text-black px-2 py-1 rounded">
              dev mode
            </div>
          )}
          <nav className="flex items-center space-x-4">
            {isAuthenticated && (
              <button
                onClick={() => setIsAddEventModalOpen(true)}
                className="bg-white text-blue-700 py-2 px-4 rounded-md hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 transition duration-300"
              >
                Add Event
              </button>
            )}
            {isAuthenticated ? renderUserMenu() : (
              <Link to="/login" className="hover:text-blue-200 transition duration-300">Sign-up or Sign-in</Link>
            )}
          </nav>
        </div>
      </div>
      <AddEventModal
        isOpen={isAddEventModalOpen}
        onClose={() => setIsAddEventModalOpen(false)}
      />
    </header>
  );
};

export default Header;
