import React from 'react';

const sizeClasses = {
  sm: 'w-8 h-8 text-xs',
  md: 'w-12 h-12 text-sm',
  lg: 'w-16 h-16 text-base',
  xl: 'w-24 h-24 text-2xl'
};

const UserAvatar = ({ user, size = 'md' }) => {
  const getInitials = (name) => {
    if (!name || name.trim() === '' || name === 'Anonymous') return 'G';
    return name.split(' ').map(n => n[0]).join('').toUpperCase().slice(0, 2);
  };

  const initials = getInitials(user?.displayName || user?.name);
  const hasPhoto = user?.photoURL;

  return (
    <div className={`${sizeClasses[size]} rounded-full overflow-hidden flex items-center justify-center bg-blue-500 text-white font-bold`}>
      {hasPhoto ? (
        <img 
          src={user.photoURL}
          alt={user.displayName || user.name || 'User'}
          className="w-full h-full object-cover"
          onError={(e) => {
            e.target.onerror = null;
            e.target.style.display = 'none';
            e.target.parentNode.textContent = initials;
          }}
        />
      ) : (
        <span>{initials}</span>
      )}
    </div>
  );
};

export default UserAvatar;