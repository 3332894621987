import { DateTime, IANAZone } from 'luxon';

export const formatSingleDateTime = (date, time, timezone) => {
  const dateTime = DateTime.fromFormat(`${date} ${time}`, 'yyyy-MM-dd HH:mm', { zone: timezone });
  return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
};

export const formatDateTime = (startDate, startTime, endDate, endTime, timezone) => {
  const start = DateTime.fromFormat(`${startDate} ${startTime}`, 'yyyy-MM-dd HH:mm', { zone: timezone });

  if (!endDate || !endTime) {
    return `${start.toFormat('MMM d, yyyy h:mm a')} ${start.toFormat('ZZZZ')}`;
  }

  const end = DateTime.fromFormat(`${endDate} ${endTime}`, 'yyyy-MM-dd HH:mm', { zone: timezone });

  if (startDate === endDate) {
    return `${start.toFormat('MMM d, yyyy')} | ${start.toFormat('h:mm a')} - ${end.toFormat('h:mm a')} ${start.toFormat('ZZZZ')}`;
  } else {
    return `${start.toFormat('MMM d, yyyy h:mm a')} - ${end.toFormat('MMM d, yyyy h:mm a')} ${start.toFormat('ZZZZ')}`;
  }
};

export const formatDateForICS = (date, time, timezone) => {
  const dateTime = DateTime.fromFormat(`${date} ${time}`, 'yyyy-MM-dd HH:mm', { zone: timezone });
  return dateTime.isValid ? dateTime.toFormat("yyyyMMdd'T'HHmmss") : DateTime.utc().toFormat("yyyyMMdd'T'HHmmss");
};

export const getIANATimeZone = (timezone) => {
  const zone = IANAZone.create(timezone);
  return zone.isValid ? zone.name : 'UTC';
};

export const escapeText = (text) => {
  return text.replace(/[\\;,]/g, (match) => '\\' + match);
};

export const wrapLine = (line, limit = 75) => {
  if (line.length <= limit) {
    return line;
  }
  const chunks = line.match(new RegExp(`.{1,${limit}}`, 'g'));
  return chunks.join('\r\n ');
};

export const isPastEvent = (endDate, endTime) => {
  const now = new Date();
  const eventEnd = new Date(`${endDate}T${endTime || '23:59:59'}`);
  return now > eventEnd;
};

// You can also update your existing formatDateTime function here if needed
