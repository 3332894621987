import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EventCard from './EventCard';
import RSVPManager from '../rsvps/RSVPManager';
import { getEventByUrl } from './eventServices';
import { useAuth } from '../Auth';
import { useNotification } from '../components/NotificationContext';
import { sendEventDetailEmail } from '../emails/emailService';
import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

const EventDetailMain = () => {
  const { eventUrl } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasUserRSVPd, setHasUserRSVPd] = useState(false);
  const [isEventCreator, setIsEventCreator] = useState(false);
  const [isPastEvent, setIsPastEvent] = useState(false);
  const { user, isAuthenticated } = useAuth();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const [isRSVPd, setIsRSVPd] = useState(false);
  const [groupNames, setGroupNames] = useState([]);

  const fetchEvent = useCallback(async () => {
    try {
      const fetchedEvent = await getEventByUrl(eventUrl);
      if (fetchedEvent) {
        setEvent(fetchedEvent);
        setIsEventCreator(isAuthenticated && user && (fetchedEvent.createdBy === user.uid || fetchedEvent.createdBy === user.email));
        const eventEndDate = new Date(`${fetchedEvent.endDate}T${fetchedEvent.endTime || '23:59'}`);
        setIsPastEvent(eventEndDate < new Date());

        // Fetch group names
        if (fetchedEvent.groupIds && fetchedEvent.groupIds.length > 0) {
          const groupsRef = collection(db, 'groups');
          const q = query(groupsRef, where('eventIds', 'array-contains', fetchedEvent.id));
          const querySnapshot = await getDocs(q);
          const names = querySnapshot.docs.map(doc => doc.data().name);
          setGroupNames(names);
        }
      } else {
        showNotification('Event not found', 'error');
        navigate('/');
      }
    } catch (error) {
      console.error('Error fetching event:', error);
      showNotification('Error fetching event', 'error');
      navigate('/');
    } finally {
      setLoading(false);
    }
  }, [eventUrl, isAuthenticated, user, showNotification, navigate]);

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent, user]);

  const handleEdit = () => {
    navigate(`/manage-event/${eventUrl}`, { 
      state: { 
        returnPath: `/event/${eventUrl}`,
        eventId: event.id, 
        eventUrl: event.url 
      } 
    });
  };

  const handleRSVPChange = (rsvpStatus) => {
    setIsRSVPd(rsvpStatus);
    setEvent(prevEvent => ({
      ...prevEvent,
      rsvpId: rsvpStatus ? 'temp-id' : null
    }));
  };

  const handleEventSaved = (updatedEvent) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      ...updatedEvent,
      groupIds: updatedEvent.groupIds // Ensure this line is present
    }));
    showNotification('Event updated successfully', 'success');
  };

  const handleSendTestEmail = async () => {
    if (!user || !event) return;

    try {
      const result = await sendEventDetailEmail(user.email, user.displayName || 'Gatherly User', event);
      if (result.success) {
        showNotification('Event detail email sent successfully', 'success');
      } else {
        showNotification('Failed to send event detail email: ' + result.error, 'error');
      }
    } catch (error) {
      console.error('Failed to send event detail email:', error);
      showNotification('Failed to send event detail email: ' + error.message, 'error');
    }
  };

  if (loading) {
    return <div className="text-center py-4">Loading...</div>;
  }

  if (!event) {
    return <div className="text-center py-4">Event not found</div>;
  }

  return (
    <div className="container mx-auto py-8">
      <div className="event-detail-container relative">
        <EventCard 
          event={{
            ...event, 
            isMyEvent: isEventCreator, 
            isPast: isPastEvent,
            rsvpId: isRSVPd ? 'temp-id' : null
          }}
          mode="detail" 
          onEdit={handleEventSaved}
        />
        {event.requiresRSVP !== false && (
          <div className="px-4 relative">
            <RSVPManager 
              event={event}
              setHasUserRSVPd={setHasUserRSVPd} 
              isEventCreator={isEventCreator} 
              isPastEvent={isPastEvent}
              onRSVPChange={handleRSVPChange}
            />
          </div>
        )}
        {process.env.NODE_ENV === 'development' && user && (
          <div className="mt-8">
            <button
              onClick={handleSendTestEmail}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Send Test Event Detail Email
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventDetailMain;
